import React from "react";
import {
    Input, Link,
    StatusIndicator
} from "@amzn/awsui-components-react/polaris";
import Constants from "../utils/Constants";
import FremontConstants from "../../../utils/Constants";
import HelperFunctions from "../../../mango/js/common/HelperFunctions";

export default class IspCutsheetTableData {
    static generateHops = (siteGroup, hopType, parentLinkType) => {
        const data = [];
        const totalHops = {
            r2r: 5, e2e: 5, m2m: 5, mmr: 1
        };

        const colors = ["#e6f2ff", "#e6f7e0", "#f0f8ff", "#ffebf2", "#f3e3f2"];
        const mapHopTypeToLabel = {
            e2e: "Lever",
            r2r: "Router",
            m2m: "Mux",
            mmr: "MMR"
        };

        for (let i = 1; i <= totalHops[hopType]; i += 1) {
            const color = colors[i - 1];

            const hop = {
                label: hopType === "mmr" ? `Site ${siteGroup.toUpperCase()} - COLO ${i} ${mapHopTypeToLabel[hopType]}` :
                    `Site ${siteGroup.toUpperCase()} - PP ${i} ${mapHopTypeToLabel[hopType]}`,
                labelA: hopType === "mmr" ? `Site ${siteGroup.toUpperCase()} - COLO ${i} Room A ${mapHopTypeToLabel[hopType]}` :
                    `Site ${siteGroup.toUpperCase()} - Patch Panel ${i} Room A ${mapHopTypeToLabel[hopType]}`,
                labelZ: hopType === "mmr" ? `Site ${siteGroup.toUpperCase()} - COLO ${i} Room Z ${mapHopTypeToLabel[hopType]}` :
                    `Site ${siteGroup.toUpperCase()} - Patch Panel ${i} Room Z ${mapHopTypeToLabel[hopType]}`,
                labelPort: `Site ${siteGroup.toUpperCase()} - Port`,
                id_pp_a: `${siteGroup}_hop_${i}_isp_panel_a_location_${parentLinkType}`,
                id_pp_z: `${siteGroup}_hop_${i}_isp_panel_z_location_${parentLinkType}`,
                id_port_a: `${siteGroup}_hop_${i}_isp_panel_a_port_${parentLinkType}`,
                id_port_z: `${siteGroup}_hop_${i}_isp_panel_z_port_${parentLinkType}`,
                id_pp_a_error: `${siteGroup}_hop_${i}_isp_panel_a_location_error_${parentLinkType}`,
                id_port_a_error: `${siteGroup}_hop_${i}_isp_panel_a_port_error_${parentLinkType}`,
                id_port_z_error: `${siteGroup}_hop_${i}_isp_panel_z_port_error_${parentLinkType}`,
                siteGroup,
                value: `${siteGroup}_hop_${i}_${parentLinkType}`,
                id: `${siteGroup}_hop_${i}_${parentLinkType}`,
                color
            };

            data.push(hop);
        }

        return data;
    };

    static ADVANCED_SORTING_OPTIONS = [
        {
            label: "Site A - Ascending Bricks, Odd to Even Ascending Routers, Odd to Even Ascending Ports",
            id: Constants.ADVANCED_SORTING_IDS.site_a_bricks_then_odd_to_even_router_then_odd_to_even_ports
        },
        {
            label: "Site Z - Ascending Bricks, Odd to Even Ascending Routers, Odd to Even Ascending Ports",
            id: Constants.ADVANCED_SORTING_IDS.site_z_bricks_then_odd_to_even_router_then_odd_to_even_ports
        }
    ];

    static COLUMN_WIDTH_CONSTANTS = {
        extraSmall: 75,
        small: 125,
        medium: 175,
        large: 200,
        extraLarge: 275,
        doubleExtraLarge: 400
    }

    static COLUMN_DEFINITIONS = [
        // First two should be sticky (I would recommend to make them collapsable)
        {
            id: Constants.ISP_ATTRIBUTES.a_line_dwdm_device,
            downloadableValue: item => item.a_line_dwdm_device,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.large,
            cell: item => item.a_line_dwdm_device,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_line_dwdm_port_1,
            downloadableValue: item => item.a_line_dwdm_port_1,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.large,
            cell: item => item.a_line_dwdm_port_1,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_line_dwdm_port_2,
            downloadableValue: item => item.a_line_dwdm_port_2,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.large,
            cell: item => item.a_line_dwdm_port_2
        },
        // Editable (link to be updated MuxToMuxLinkInstanceId)
        {
            id: Constants.ISP_ATTRIBUTES.a_line_dwdm_device_location,
            downloadableValue: item => item.a_line_dwdm_device_location,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.extraLarge,
            cell: item => item.a_line_dwdm_device_location,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.a_line_dwdm_device_location)}
                        autoFocus
                        placeholder="Enter Line DWDM location"
                    />
                )
            },
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_ops_dwdm_port,
            downloadableValue: item => item.a_ops_dwdm_port,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.a_ops_dwdm_port
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_rfts_filter,
            downloadableValue: item => item.a_rfts_filter,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.a_rfts_filter,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_rfts_in_port_1,
            downloadableValue: item => item.a_rfts_in_port_1,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.a_rfts_in_port_1,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_rfts_out_port_1,
            downloadableValue: item => item.a_rfts_out_port_1,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.a_rfts_out_port_1,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_rfts_in_port_2,
            downloadableValue: item => item.a_rfts_in_port_2,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.a_rfts_in_port_2,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_rfts_out_port_2,
            downloadableValue: item => item.a_rfts_out_port_2,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.a_rfts_out_port_2,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_pluggable,
            downloadableValue: item => item.a_pluggable,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.a_pluggable,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_connector_type,
            downloadableValue: item => item.a_connector_type,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.a_connector_type,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_pluggable,
            downloadableValue: item => item.z_pluggable,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.z_pluggable,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_connector_type,
            downloadableValue: item => item.z_connector_type,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.z_connector_type,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_rfts_out_port_2,
            downloadableValue: item => item.z_rfts_out_port_2,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.z_rfts_out_port_2,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_rfts_in_port_2,
            downloadableValue: item => item.z_rfts_in_port_2,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.z_rfts_in_port_2,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_rfts_out_port_1,
            downloadableValue: item => item.z_rfts_out_port_1,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.z_rfts_out_port_1,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_rfts_in_port_1,
            downloadableValue: item => item.z_rfts_in_port_1,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.z_rfts_in_port_1,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_rfts_filter,
            downloadableValue: item => item.z_rfts_filter,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.z_rfts_filter,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_ops_dwdm_port,
            downloadableValue: item => item.z_ops_dwdm_port,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.z_ops_dwdm_port
        },
        // Editable (link to be updated MuxToMuxLinkInstanceId)
        {
            id: Constants.ISP_ATTRIBUTES.z_line_dwdm_device_location,
            downloadableValue: item => item.z_line_dwdm_device_location,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.extraLarge,
            cell: item => item.z_line_dwdm_device_location,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.z_line_dwdm_device_location)}
                        autoFocus
                        placeholder="Enter DWDM location"
                    />
                )
            },
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_line_dwdm_port_2,
            downloadableValue: item => item.z_line_dwdm_port_2,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.z_line_dwdm_port_2
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_line_dwdm_port_1,
            downloadableValue: item => item.z_line_dwdm_port_1,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.z_line_dwdm_port_1,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_line_dwdm_device,
            downloadableValue: item => item.z_line_dwdm_device,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.z_line_dwdm_device,
            isRowHeader: true
        },
        // Make the above two sticky for z site
        {
            id: Constants.ISP_ATTRIBUTES.mux_to_mux_link_instance_id,
            header: "MuxToMuxLinkInstanceId",
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.mux_to_mux_link_instance_id,
            isRowHeader: true
        },
        // Editable comment
        {
            id: Constants.ISP_ATTRIBUTES.comment,
            header: Constants.ISP_ATTRIBUTES.comment,
            downloadableValue: item => item.comment,
            downloadableColumnHeader: Constants.ISP_ATTRIBUTES.comment,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.extraLarge,
            cell: item => item.comment,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.comment)}
                        autoFocus
                        placeholder="Enter Comment"
                    />
                )
            },
            isRowHeader: true
        },
        // Below two columns should be sticky (collapsable)
        {
            id: Constants.ISP_ATTRIBUTES.fiber_path,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.small,
            downloadableValue: item => item.fiber_path,
            cell: item => item.fiber_path,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_hostname,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.a_hostname,
            cell: item => item.a_hostname,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_interface,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.extraSmall,
            downloadableValue: item => item.a_interface,
            cell: item => item.a_interface,
            isRowHeader: true
        },
        // editable (RouterToRouterLinkInstanceID)
        {
            id: Constants.ISP_ATTRIBUTES.a_location,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.extraLarge,
            downloadableValue: item => item.a_location,
            cell: item => item.a_location,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.a_location)}
                        autoFocus
                        placeholder="Enter A location"
                    />
                )
            }
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_cage,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.a_cage,
            cell: item => item.a_cage,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_patch_panel,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.a_patch_panel,
            cell: item => item.a_patch_panel,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_rack,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.a_rack,
            cell: item => item.a_rack,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_optic_type,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.a_optic_type,
            cell: item => item.a_optic_type,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_connector_type,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.a_connector_type,
            cell: item => item.a_connector_type,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_fiber_type,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.a_fiber_type,
            cell: item => item.a_fiber_type,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_port_speed,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.a_port_speed,
            cell: item => item.a_port_speed,
            isRowHeader: true
        },
        // Editable (Link to be updated EncryptionToEncryptionLinkInstanceID)
        {
            id: Constants.ISP_ATTRIBUTES.a_lever_location,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.extraLarge,
            downloadableValue: item => item.a_lever_location,
            cell: item => item.a_lever_location,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        disabled={!item.encryption_to_encryption_link_instance_id}
                        onFocus={() => setValue(item.a_lever_location)}
                        autoFocus
                        placeholder="Enter A lever location"
                    />
                )
            },
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_lever_hostname,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.a_lever_hostname,
            cell: item => item.a_lever_hostname,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_client_lever_interface,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.a_client_lever_interface,
            cell: item => item.a_client_lever_interface,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_client_lever_optic_type,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.a_client_lever_optic_type,
            cell: item => item.a_client_lever_optic_type,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_trunk_lever_interface,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.a_trunk_lever_interface,
            cell: item => item.a_trunk_lever_interface,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_trunk_lever_optic_type,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.a_trunk_lever_optic_type,
            cell: item => item.a_trunk_lever_optic_type,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_osp_panel_location,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.doubleExtraLarge,
            cell: item => (
                <div>
                    {!!item.validation_error &&
                        <StatusIndicator type="error"/>
                    }
                    {item.a_osp_panel_location}
                </div>
            ),
            downloadableValue: item => item.a_osp_panel_location,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                validation: item => item.validation_error,
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.a_osp_panel_location)}
                        autoFocus
                        placeholder="Enter Consumed Passive Patch Panel"
                    />
                )
            }
        },
        {
            id: Constants.ISP_ATTRIBUTES.a_osp_panel_ports,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.large,
            cell: item => (
                <div>
                    {!!item.validation_error &&
                        <StatusIndicator type="error"/>
                    }
                    {item.a_osp_panel_ports}
                </div>
            ),
            downloadableValue: item => item.a_osp_panel_ports,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                validation: item => item.validation_error,
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.a_osp_panel_ports)}
                        autoFocus
                        placeholder="Enter Consumed Passive Port"
                    />
                )
            }
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_osp_panel_location,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.doubleExtraLarge,
            downloadableValue: item => item.z_osp_panel_location,
            cell: item => item.z_osp_panel_location,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.z_osp_panel_location)}
                        autoFocus
                        placeholder="Enter Consumed Passive Patch Panel"
                    />
                )
            }
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_osp_panel_ports,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.large,
            downloadableValue: item => item.z_osp_panel_ports,
            cell: item => item.z_osp_panel_ports,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.z_osp_panel_ports)}
                        autoFocus
                        placeholder="Enter Consumed Passive Port"
                    />
                )
            }
        },
        // Dynamic Columns For *_client_dwdm_port_Site* and *_trunk_dwdm_port_site should be after this...
        {
            id: Constants.ISP_ATTRIBUTES.z_trunk_lever_optic_type,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.z_trunk_lever_optic_type,
            cell: item => item.z_trunk_lever_optic_type,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_trunk_lever_interface,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.z_trunk_lever_interface,
            cell: item => item.z_trunk_lever_interface,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_client_lever_optic_type,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.z_client_lever_optic_type,
            cell: item => item.z_client_lever_optic_type,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_client_lever_interface,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.z_client_lever_interface,
            cell: item => item.z_client_lever_interface,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_lever_hostname,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.z_lever_hostname,
            cell: item => item.z_lever_hostname,
            isRowHeader: true
        },
        // Editable (Link to be updated EncryptionToEncryptionLinkInstanceID)
        {
            id: Constants.ISP_ATTRIBUTES.z_lever_location,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.extraLarge,
            downloadableValue: item => item.z_lever_location,
            cell: item => item.z_lever_location,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.z_lever_location)}
                        disabled={!item.encryption_to_encryption_link_instance_id}
                        autoFocus
                        placeholder="Enter Z Lever location"
                    />
                )
            },
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_port_speed,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.z_port_speed,
            cell: item => item.z_port_speed,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_fiber_type,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.z_fiber_type,
            cell: item => item.z_fiber_type,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_connector_type,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.z_connector_type,
            cell: item => item.z_connector_type,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_optic_type,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.z_optic_type,
            cell: item => item.z_optic_type,
            isRowHeader: true
        },
        // Editable (link to be updated RouterToRouterLinkInstanceID)
        {
            id: Constants.ISP_ATTRIBUTES.z_location,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.extraLarge,
            downloadableValue: item => item.z_location,
            cell: item => item.z_location,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.z_location)}
                        autoFocus
                        placeholder="Enter Z location"
                    />
                )
            }
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_cage,
            header: Constants.ISP_ATTRIBUTES.z_cage,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => (
                <div>
                    {!!item.z_side_error &&
                        <StatusIndicator type="error"/>
                    }
                    {item.z_cage}
                </div>
            ),
            downloadableValue: item => item.z_cage,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                validation: item => item.z_side_error,
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.z_cage)}
                        autoFocus
                        placeholder="Enter Z Cage"
                    />
                )
            }
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_patch_panel,
            header: Constants.ISP_ATTRIBUTES.z_patch_panel,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => (
                <div>
                    {!!item.z_side_error &&
                        <StatusIndicator type="error"/>
                    }
                    {item.z_patch_panel}
                </div>
            ),
            downloadableValue: item => item.z_patch_panel,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                validation: item => item.z_side_error,
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.z_patch_panel)}
                        autoFocus
                        placeholder="Enter Z Patch Panel"
                    />
                )
            }
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_rack,
            header: Constants.ISP_ATTRIBUTES.z_rack,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => (
                <div>
                    {!!item.z_side_error &&
                        <StatusIndicator type="error"/>
                    }
                    {item.z_rack}
                </div>
            ),
            downloadableValue: item => item.z_rack,
            editConfig: {
                ariaLabel: "Name",
                editIconAriaLabel: "editable",
                validation: item => item.z_side_error,
                editingCell: (
                    item,
                    { currentValue, setValue }
                ) => (
                    <Input
                        value={currentValue}
                        onChange={event => (
                            setValue(event.detail.value)
                        )}
                        onFocus={() => setValue(item.z_rack)}
                        autoFocus
                        placeholder="Enter Z Rack"
                    />
                )
            }
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_interface,
            header: Constants.ISP_ATTRIBUTES.z_interface,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.small,
            downloadableValue: item => item.z_interface,
            cell: item => item.z_interface,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.z_hostname,
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            downloadableValue: item => item.z_hostname,
            cell: item => item.z_hostname,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.router_to_router_link_instance_id,
            header: "RouterToRouterLinkInstanceID",
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.router_to_router_link_instance_id,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.encryption_to_encryption_link_instance_id,
            header: "EncryptionToEncryptionLinkInstanceID",
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            cell: item => item.encryption_to_encryption_link_instance_id,
            isRowHeader: true
        },
        {
            id: Constants.ISP_ATTRIBUTES.intra_dc_router_to_intra_dc_router_link_instance_id,
            header: "LinkId",
            minWidth: IspCutsheetTableData.COLUMN_WIDTH_CONSTANTS.medium,
            isRowHeader: true,
            cell: item => (<Link href={`${FremontConstants.MANGO_ROUTES.linkDetails}/${HelperFunctions.getUUID(item.intra_dc_router_to_intra_dc_router_link_instance_id)}`}>{HelperFunctions.getUUID(item.intra_dc_router_to_intra_dc_router_link_instance_id)}</Link>)
        }
    ]

    static getColumnDefinitions = (columnDefinitionsList) => {
        const columnDefinitions = [];
        columnDefinitionsList.forEach((columnDefinition) => {
            columnDefinitions.push(
                IspCutsheetTableData.COLUMN_DEFINITIONS.find(column => column.id === columnDefinition)
            );
        });
        return columnDefinitions;
    }

    static LINE_COLUMN_DEFINITIONS_LIST = [
        Constants.ISP_ATTRIBUTES.a_line_dwdm_device,
        Constants.ISP_ATTRIBUTES.a_line_dwdm_port_1,
        Constants.ISP_ATTRIBUTES.a_line_dwdm_port_2,
        Constants.ISP_ATTRIBUTES.a_line_dwdm_device_location,
        Constants.ISP_ATTRIBUTES.a_ops_dwdm_port,
        Constants.ISP_ATTRIBUTES.a_rfts_filter,
        Constants.ISP_ATTRIBUTES.a_rfts_in_port_1,
        Constants.ISP_ATTRIBUTES.a_rfts_out_port_1,
        Constants.ISP_ATTRIBUTES.a_rfts_in_port_2,
        Constants.ISP_ATTRIBUTES.a_rfts_out_port_2,
        Constants.ISP_ATTRIBUTES.a_pluggable,
        Constants.ISP_ATTRIBUTES.a_connector_type,
        Constants.ISP_ATTRIBUTES.z_pluggable,
        Constants.ISP_ATTRIBUTES.z_connector_type,
        Constants.ISP_ATTRIBUTES.z_rfts_out_port_2,
        Constants.ISP_ATTRIBUTES.z_rfts_in_port_2,
        Constants.ISP_ATTRIBUTES.z_rfts_out_port_1,
        Constants.ISP_ATTRIBUTES.z_rfts_in_port_1,
        Constants.ISP_ATTRIBUTES.z_rfts_filter,
        Constants.ISP_ATTRIBUTES.z_ops_dwdm_port,
        Constants.ISP_ATTRIBUTES.z_line_dwdm_device_location,
        Constants.ISP_ATTRIBUTES.z_line_dwdm_port_2,
        Constants.ISP_ATTRIBUTES.z_line_dwdm_port_1,
        Constants.ISP_ATTRIBUTES.z_line_dwdm_device,
        Constants.ISP_ATTRIBUTES.comment,
        Constants.ISP_ATTRIBUTES.mux_to_mux_link_instance_id
    ]

    static CLIENT_COLUMN_DEFINITIONS_LIST = [
        Constants.ISP_ATTRIBUTES.fiber_path,
        Constants.ISP_ATTRIBUTES.a_hostname,
        Constants.ISP_ATTRIBUTES.a_interface,
        Constants.ISP_ATTRIBUTES.a_location,
        Constants.ISP_ATTRIBUTES.a_optic_type,
        Constants.ISP_ATTRIBUTES.a_connector_type,
        Constants.ISP_ATTRIBUTES.a_fiber_type,
        Constants.ISP_ATTRIBUTES.a_port_speed,
        Constants.ISP_ATTRIBUTES.a_lever_location,
        Constants.ISP_ATTRIBUTES.a_lever_hostname,
        Constants.ISP_ATTRIBUTES.a_client_lever_interface,
        Constants.ISP_ATTRIBUTES.a_client_lever_optic_type,
        Constants.ISP_ATTRIBUTES.a_trunk_lever_interface,
        Constants.ISP_ATTRIBUTES.a_trunk_lever_optic_type,
        Constants.ISP_ATTRIBUTES.a_osp_panel_location,
        Constants.ISP_ATTRIBUTES.a_osp_panel_ports,
        Constants.ISP_ATTRIBUTES.z_osp_panel_location,
        Constants.ISP_ATTRIBUTES.z_osp_panel_ports,
        Constants.ISP_ATTRIBUTES.z_trunk_lever_optic_type,
        Constants.ISP_ATTRIBUTES.z_trunk_lever_interface,
        Constants.ISP_ATTRIBUTES.z_client_lever_optic_type,
        Constants.ISP_ATTRIBUTES.z_client_lever_interface,
        Constants.ISP_ATTRIBUTES.z_lever_hostname,
        Constants.ISP_ATTRIBUTES.z_lever_location,
        Constants.ISP_ATTRIBUTES.z_port_speed,
        Constants.ISP_ATTRIBUTES.z_fiber_type,
        Constants.ISP_ATTRIBUTES.z_connector_type,
        Constants.ISP_ATTRIBUTES.z_optic_type,
        Constants.ISP_ATTRIBUTES.z_location,
        Constants.ISP_ATTRIBUTES.z_interface,
        Constants.ISP_ATTRIBUTES.z_hostname,
        Constants.ISP_ATTRIBUTES.router_to_router_link_instance_id,
        Constants.ISP_ATTRIBUTES.encryption_to_encryption_link_instance_id
    ]

    static INTERNAL_CABLING_COLUMN_DEFINITIONS_LIST = [
        Constants.ISP_ATTRIBUTES.a_hostname,
        Constants.ISP_ATTRIBUTES.a_interface,
        Constants.ISP_ATTRIBUTES.a_cage,
        Constants.ISP_ATTRIBUTES.a_rack,
        Constants.ISP_ATTRIBUTES.a_patch_panel,
        Constants.ISP_ATTRIBUTES.a_optic_type,
        Constants.ISP_ATTRIBUTES.a_connector_type,
        Constants.ISP_ATTRIBUTES.a_fiber_type,
        Constants.ISP_ATTRIBUTES.a_port_speed,
        Constants.ISP_ATTRIBUTES.a_pluggable,
        Constants.ISP_ATTRIBUTES.z_pluggable,
        Constants.ISP_ATTRIBUTES.z_port_speed,
        Constants.ISP_ATTRIBUTES.z_fiber_type,
        Constants.ISP_ATTRIBUTES.z_connector_type,
        Constants.ISP_ATTRIBUTES.z_optic_type,
        Constants.ISP_ATTRIBUTES.z_patch_panel,
        Constants.ISP_ATTRIBUTES.z_rack,
        Constants.ISP_ATTRIBUTES.z_cage,
        Constants.ISP_ATTRIBUTES.z_interface,
        Constants.ISP_ATTRIBUTES.z_hostname,
        Constants.ISP_ATTRIBUTES.intra_dc_router_to_intra_dc_router_link_instance_id
    ];

    static BACKBONE_CLIENT_MIGRATION_COLUMN_DEFINITIONS_LIST = [
        Constants.ISP_ATTRIBUTES.fiber_path,
        Constants.ISP_ATTRIBUTES.a_hostname,
        Constants.ISP_ATTRIBUTES.a_interface,
        Constants.ISP_ATTRIBUTES.a_location,
        Constants.ISP_ATTRIBUTES.a_optic_type,
        Constants.ISP_ATTRIBUTES.a_connector_type,
        Constants.ISP_ATTRIBUTES.a_fiber_type,
        Constants.ISP_ATTRIBUTES.a_port_speed,
        Constants.ISP_ATTRIBUTES.z_port_speed,
        Constants.ISP_ATTRIBUTES.z_fiber_type,
        Constants.ISP_ATTRIBUTES.z_connector_type,
        Constants.ISP_ATTRIBUTES.z_optic_type,
        Constants.ISP_ATTRIBUTES.z_location,
        Constants.ISP_ATTRIBUTES.z_interface,
        Constants.ISP_ATTRIBUTES.z_hostname,
        Constants.ISP_ATTRIBUTES.router_to_router_link_instance_id
    ]

    static LINE_COLUMN_DEFINITIONS = this.getColumnDefinitions(this.LINE_COLUMN_DEFINITIONS_LIST)
    static CLIENT_COLUMN_DEFINITIONS = this.getColumnDefinitions(this.CLIENT_COLUMN_DEFINITIONS_LIST)
    static INTERNAL_CABLING_COLUMN_DEFINITIONS =
        this.getColumnDefinitions(this.INTERNAL_CABLING_COLUMN_DEFINITIONS_LIST)
    static BACKBONE_CLIENT_MIGRATION_COLUMN_DEFINITIONS =
        this.getColumnDefinitions(this.BACKBONE_CLIENT_MIGRATION_COLUMN_DEFINITIONS_LIST)
}
