import React, { Component } from "react";
/* eslint-disable import/no-duplicates */
import { SpaceBetween } from "@amzn/awsui-components-react";
import { Autosuggest, Flashbar, Input, StatusIndicator } from "@amzn/awsui-components-react/polaris";
/* eslint-enable import/no-duplicates */
import Constants from "../../../mango/js/utils/Constants";
import LightHouseHeader from "../common/LightHouseHeader";
import HelperFunctions from "../../../lighthouse/js/common/HelperFunctions";
import MangoHelperFunctions from "../../../mango/js/common/HelperFunctions";
import IspCutsheetTableV2 from "./IspCutsheetTableV2";
import IspCutsheetTableData from "./IspCutsheetTableData";
import LinkHierarchyHelper from "../../../mango/js/link/LinkHierarchyHelper";

import LinkServiceBackendClient from "../../../mango/js/common/LinkServiceBackendClient";
import OspDashboardHelper from "./OspDashboardHelper";
import IspDashboardHelper from "./IspDashboardHelper";
import ConstantLightHouse from "../utils/Constants";
import OspPanelLocationSearchHandler from "./OspPanelLocationSearchHandler";

export default class IspDashboardV2 extends Component {
    state = {
        flashbar: {
            type: "",
            text: ""
        },
        updatedCutsheetKeyValuePairs: [],
        originalCutsheetKeyValuePairs: [],
        updatedColumnDefinitions: [],
        originalColumnDefinitions: [],
        ospPanelLocationResults: [],
        panelLocationWithPortsToLinkMap: new Map(),
        ippmSearchResults: [],
        searchedTerms: new Set(),
        patchPanelALocations: [],
        patchPanelALocationsSelect: [],
        notificationToDisplay: [],
        readyToSubmitLiuInformation: false,
        disabled: true,
        submissionInProgress: false,
        hopOptionsDropDown: [],
        isOspLocationEditDisabled: true,
        isInitialSearch: true
    }

    componentDidMount = async () => {
        let columnDefinitions;
        switch (this.props.cutsheetType) {
        case ConstantLightHouse.ISP_CUTSHEET_TYPE.LINE:
            columnDefinitions = IspCutsheetTableData.LINE_COLUMN_DEFINITIONS;
            break;
        case ConstantLightHouse.ISP_CUTSHEET_TYPE.CLIENT:
            columnDefinitions = IspCutsheetTableData.CLIENT_COLUMN_DEFINITIONS;
            break;
        case ConstantLightHouse.ISP_CUTSHEET_TYPE.INTERNAL_CABLING:
            columnDefinitions = IspCutsheetTableData.INTERNAL_CABLING_COLUMN_DEFINITIONS;
            // When cutsheet type is INTERNAL CABLING, make z_interface editable
            columnDefinitions = this.updateZInterfaceForInternalCabling(columnDefinitions);
            break;
        case ConstantLightHouse.ISP_CUTSHEET_TYPE.BACKBONE_CLIENT_MIGRATION:
            columnDefinitions = IspCutsheetTableData.BACKBONE_CLIENT_MIGRATION_COLUMN_DEFINITIONS;
            break;
        default:
            columnDefinitions = IspCutsheetTableData.CLIENT_COLUMN_DEFINITIONS;
        }

        columnDefinitions = IspDashboardHelper.updateColumnDefinitions(columnDefinitions);
        columnDefinitions = this.updateOSPLocationCell(columnDefinitions);
        columnDefinitions = this.addSubmitOnBlurToEditableCells(columnDefinitions);

        // Store original definitions before applying DX specific settings
        const basicColumnDefinitions = [...columnDefinitions];

        // Apply DX specific settings
        const isDxView = IspDashboardHelper.isDxViewToggled(this.props.cutsheetType);
        columnDefinitions = isDxView
            ? IspDashboardHelper.UpdateDxView(columnDefinitions)
            : IspDashboardHelper.updateColumnVisibility(columnDefinitions, this.props.cutsheetType);

        this.setState({
            updatedColumnDefinitions: columnDefinitions,
            originalColumnDefinitions: basicColumnDefinitions,
            isOspLocationEditDisabled: true,
            handleColumnName: isDxView
        });

        await this.executeGetIsp();

        // Only add additional columns for regular cutsheet view
        if (!isDxView) {
            this.addAdditionalColumnsForIspCutsheets(this.state.updatedCutsheetKeyValuePairs);
            this.addRemoveLiuColumns([]);
        }
    }

    getOSPPanelLocations = async (searchTerms) => {
        HelperFunctions.dismissFlashbar(this, {
            isFirstTimeOnPage: false,
            notificationToDisplay: []
        });
        let response = null;
        try {
            response =
                await OspDashboardHelper.getOspPanelLocationInfo(this.linkServiceBackendClient, searchTerms);
        } catch (error) {
            console.log("Error in getting OSP Panel Locations ", error);
            this.setState({
                notificationToDisplay:
                    HelperFunctions.generateErrorMessageForFlashbar(
                        "Failed to search for OSP Panel Locations",
                        error.toString(),
                        true,
                        this
                    )
            });
        }
        if (response) {
            this.setState(
                {
                    ospPanelLocationResults: response.ospPanelLocation,
                    panelLocationWithPortsToLinkMap: response.panelLocationWithPortToLinkMap
                }
            );
        }
        return response;
    }

    getIPPMInformation = async (searchTerm) => {
        try {
            // Check if the search term has already been found for
            if (!this.state.patchPanelALocations.includes(searchTerm)) {
                const searchResults = await this.linkServiceBackendClient.searchIspPanelConfig(searchTerm);
                if (searchResults.PatchPanelConfigObjects.length === 0) {
                    throw new Error("Invalid Patch Panel Location.  Please try again.");
                }
                // Extract existing locations from ippmSearchResults
                const existingPatchPanelALocations = new Set(
                    this.state.ippmSearchResults.map(result => result.PatchPanelLocationA)
                );

                // Filter out searchResults that already exist in ippmSearchResults
                const filteredResults = searchResults.PatchPanelConfigObjects
                    .filter(result => !existingPatchPanelALocations.has(result.PatchPanelLocationA))
                    .filter(result => !!result.PanelId);

                // Create an updated array of ippmSearchResults
                const updatedIppmSearchResults = [...this.state.ippmSearchResults, ...filteredResults];

                this.setState({
                    isLiuSearchInProgress: false,
                    ippmSearchResults: updatedIppmSearchResults,
                    searchedTerms: this.state.searchedTerms.add(searchTerm),
                    patchPanelALocations: updatedIppmSearchResults.map(result => result.PatchPanelLocationA),
                    patchPanelALocationsSelect: updatedIppmSearchResults
                        .map(result => result.PatchPanelLocationA)
                        .map(str => ({ label: str, value: str })),
                    notificationToDisplay:
                        HelperFunctions.generateSuccessMessageForFlashbar(
                            "Successfully searched for patch panel", "", true, this
                        )
                });
            }
        } catch (e) {
            this.setState({
                notificationToDisplay:
                    HelperFunctions.generateErrorMessageForFlashbar(
                        "Failed to search for ISP Patch Panel Configs",
                        e.toString(),
                        true,
                        this
                    )
            });
        }
    }

    getRowIndexToUpdate = (item, updatedCutsheetKeyValuePairs) => {
        switch (this.props.cutsheetType) {
        case ConstantLightHouse.ISP_CUTSHEET_TYPE.LINE:
            return updatedCutsheetKeyValuePairs.findIndex(
                row => row.mux_to_mux_link_instance_id === item.mux_to_mux_link_instance_id
            );
        case ConstantLightHouse.ISP_CUTSHEET_TYPE.INTERNAL_CABLING:
            return updatedCutsheetKeyValuePairs.findIndex(
                row =>
                    row.intra_dc_router_to_intra_dc_router_link_instance_id ===
                            item.intra_dc_router_to_intra_dc_router_link_instance_id
            );
        default:
            return updatedCutsheetKeyValuePairs.findIndex(
                row => row.router_to_router_link_instance_id === item.router_to_router_link_instance_id
            );
        }
    }

    ispCutsheetTableRef = React.createRef();

    handleCopyToSelectedRows = (ospLocationInformation) => {
        const selectedRows = this.ispCutsheetTableRef.current.getSelectedRows();
        const updatedCutsheetKeyValuePairs = JSON.parse(JSON.stringify(this.state.updatedCutsheetKeyValuePairs));
        if (!selectedRows || selectedRows.length === 0 || !ospLocationInformation) return;

        for (let index = 0; index < selectedRows.length; index += 1) {
            const rowIndex = this.getRowIndexToUpdate(selectedRows[index], updatedCutsheetKeyValuePairs);

            updatedCutsheetKeyValuePairs[rowIndex][ConstantLightHouse.ISP_ATTRIBUTES.a_osp_panel_location] =
                ospLocationInformation.aOspPanelLocation;
            updatedCutsheetKeyValuePairs[rowIndex][ConstantLightHouse.ISP_ATTRIBUTES.z_osp_panel_location] =
                ospLocationInformation.zOspPanelLocation;

            if (index < ospLocationInformation.aAvailablePorts.length
                && index < ospLocationInformation.zAvailablePorts.length) {
                // Update the row with OSP panel information with available links
                // Do not populate the rows for which we don't have links
                const aPortId = ospLocationInformation.aAvailablePorts[index];
                const zPortId = ospLocationInformation.zAvailablePorts[index];
                updatedCutsheetKeyValuePairs[rowIndex][ConstantLightHouse.ISP_ATTRIBUTES.a_osp_panel_ports] =
                    aPortId;
                updatedCutsheetKeyValuePairs[rowIndex][ConstantLightHouse.ISP_ATTRIBUTES.z_osp_panel_ports] =
                    zPortId;
            }
        }
        this.setState({
            updatedCutsheetKeyValuePairs
        });
    }
    updateOSPLocationCell = columnDefinitions => columnDefinitions.map((column) => {
        if (column.id === ConstantLightHouse.ISP_ATTRIBUTES.a_osp_panel_location ||
                column.id === ConstantLightHouse.ISP_ATTRIBUTES.z_osp_panel_location) {
            return {
                ...column,
                editConfig: {
                    ...column.editConfig,
                    disabledReason: () => {
                        if (this.state.isOspLocationEditDisabled) {
                            return "Please search OSP location first." +
                                    " If no results found click on Edit OSP Location";
                        }
                        return undefined;
                    }
                }
            };
        }
        return column;
    })

    addSubmitOnBlurToEditableCells = columnDefinitions => columnDefinitions.map((column) => {
        if (ConstantLightHouse.EDITABLE_ISP_ATTRIBUTES_WITH_AUTO_SAVE.includes(column.id)) {
            return {
                ...column,
                editConfig: {
                    ...column.editConfig,
                    editingCell: (
                        item,
                        { currentValue, setValue }
                    ) => (
                        <Input
                            value={currentValue}
                            onChange={event => (
                                setValue(event.detail.value)
                            )}
                            onFocus={() => setValue(item[column.id])}
                            onBlur={() =>
                                this.ispCutsheetTableRef.current.handleOnSubmitTableCell(item, column, currentValue)
                            }
                            autoFocus
                            placeholder={ConstantLightHouse.getPlaceholderText(column.id)}
                        />
                    )
                }
            };
        }
        return column;
    })

    updateZInterfaceForInternalCabling = columnDefinitions => columnDefinitions.map((column) => {
        if (column.id === ConstantLightHouse.ISP_ATTRIBUTES.z_interface) {
            return {
                ...column,
                cell: item => (
                    <div>
                        {!!item.z_side_error &&
                        <StatusIndicator type="error"/>
                        }
                        {IspDashboardHelper.truncateZInterface(item.z_interface)}
                    </div>
                ),
                editConfig: {
                    ariaLabel: "Name",
                    editIconAriaLabel: "editable",
                    validation: item => item.z_side_error,
                    editingCell: (
                        item,
                        { currentValue, setValue }
                    ) => (
                        <Input
                            value={currentValue}
                            onChange={event => (
                                setValue(event.detail.value)
                            )}
                            onFocus={() => setValue(IspDashboardHelper.truncateZInterface(item.z_interface))}
                            autoFocus
                            placeholder="Enter Z Interface"
                        />
                    )
                }
            };
        }
        return column;
    });

    handleOnClickEditOSPLocation = () => {
        this.setState({
            isOspLocationEditDisabled: false
        });
    }


    /**
     * Executes getISP API
     * @returns {Promise<void>}
     */
    executeGetIsp = async () => {
        // Clear out search results
        this.setState({
            updatedCutsheetKeyValuePairs: []
        });
        HelperFunctions.dismissFlashbar(this, {
            isLiuSearchInProgress: true,
            isFirstTimeOnPage: false
        });
        try {
            const searchIspResponse = await this.linkServiceBackendClient.getIspData(
                this.props.searchTerm,
                this.props.cutsheetType
            );
            if (this.props.cutsheetType === ConstantLightHouse.ISP_CUTSHEET_TYPE.LINE) {
                searchIspResponse.CutsheetKeyValuePairs
                    .sort((a, b) => {
                        const hostNameOrder = a.a_line_dwdm_device.localeCompare(b.a_line_dwdm_device);
                        const interfaceOrder = a.a_line_dwdm_port_1.localeCompare(b.a_line_dwdm_port_1);
                        return hostNameOrder || interfaceOrder;
                    });
            } else if (this.props.cutsheetType === ConstantLightHouse.ISP_CUTSHEET_TYPE.INTERNAL_CABLING) {
                searchIspResponse.CutsheetKeyValuePairs
                    .sort((a, b) => {
                        const hostNameOrder = HelperFunctions.sortHostname(a.a_hostname, b.a_hostname);
                        // Extract numbers from strings
                        const interfaceA = a.a_interface.match(/\d+/);
                        const interfaceB = b.a_interface.match(/\d+/);
                        let interfaceOrder = 0;
                        // If both are NaN (not numbers), compare alphabetically
                        if (interfaceA === null && interfaceB === null) {
                            interfaceOrder = a.a_interface.localeCompare(b.a_interface);
                        } else if (interfaceA === null) {
                            interfaceOrder = -1;
                        } else if (interfaceB === null) {
                            interfaceOrder = 1;
                        } else {
                        // If both have numbers
                            interfaceOrder = interfaceA - interfaceB;
                        }
                        return hostNameOrder || interfaceOrder;
                    });
            } else {
                // sort the isp cutsheet by hostname for client case
                searchIspResponse.CutsheetKeyValuePairs
                    .sort((a, b) => {
                        const fiberPathOrder = a.fiber_path.localeCompare(b.fiber_path);
                        const hostNameOrder = HelperFunctions.sortHostname(a.a_hostname, b.a_hostname);
                        const interfaceOrder = a.a_interface.split("/")[2] -
                            b.a_interface.split("/")[2];
                        const jrpInterface = a.a_interface.split("jrp")[1] - b.a_interface.split("jrp")[1];
                        // TODO: find a more generic way to sort interfaces
                        return fiberPathOrder || hostNameOrder || interfaceOrder || jrpInterface;
                    });
            }

            // Set the initial state of isp cutsheet
            this.setState({
                originalCutsheetKeyValuePairs: JSON.parse(JSON.stringify(searchIspResponse.CutsheetKeyValuePairs)),
                updatedCutsheetKeyValuePairs: JSON.parse(JSON.stringify(searchIspResponse.CutsheetKeyValuePairs)),
                disabled: searchIspResponse.CutsheetKeyValuePairs.length === 0
            });

            // we need to adjust some keys to account for Flipping A/Z ends
            this.fixKeysForMultiHops();
        } catch (e) {
            this.setState({
                notificationToDisplay:
                    HelperFunctions.generateErrorMessageForFlashbar(
                        "Failed to retrieve cutsheet for searchTerm and cutsheetType",
                        e.toString(),
                        true,
                        this
                    )
            });
        }

        this.setState({
            isLiuSearchInProgress: false
        });
    }
    handleFlashbarClose = () => {
        HelperFunctions.dismissFlashbar(this, {});
    };

    linkServiceBackendClient = new LinkServiceBackendClient();

    handlePortAInputChangeForLiu = (item, column, newValue, selectedRows) => {
        let updatedCutsheetKeyValuePairs = JSON.parse(JSON.stringify(this.state.updatedCutsheetKeyValuePairs));

        const rowIndexToUpdate = this.getRowIndexToUpdate(item, updatedCutsheetKeyValuePairs);

        // get the value of patchPanelA for this port
        const patchPanelA = this.state.hopOptionsDropDown
            .filter(hop => hop.id_port_a === column.id || hop.id_port_z === column.id)
            .map(hop => hop.id_pp_a);
        const patchPanelAValue =
            updatedCutsheetKeyValuePairs[rowIndexToUpdate][patchPanelA];

        // if the regex defined is not followed we return a blank
        if ((/^\d+$/.test(newValue) || /^\d+-\d+$/.test(newValue))) {
            updatedCutsheetKeyValuePairs[rowIndexToUpdate][column.id] = newValue;
        } else {
            updatedCutsheetKeyValuePairs[rowIndexToUpdate][column.id] = "";
        }

        // MASS UPDATE FOR THE PORTS IS DONE NEXT
        // first get this row's consumer primary key
        const rowUUID = HelperFunctions.getConsumerUUID(item, this.props.cutsheetType);

        // second get the UUIDs of all the selected rows
        const listOfSelectedRowsUUID = selectedRows
            .map(row => HelperFunctions.getConsumerUUID(row, this.props.cutsheetType));
        // if the row which is being updated is one of the selected rows
        // then we can massupdate the ports of other selected rows here
        if (listOfSelectedRowsUUID.includes(rowUUID)) {
            // if so then all the rows which are selected need to update their value to the newValue
            let newValueCounter = parseInt(newValue, 10);
            updatedCutsheetKeyValuePairs = updatedCutsheetKeyValuePairs.map((row) => {
                // here there are three conditions:
                // 1. the row is actually a selectedRow
                // 2. the LIU patch panel is also filled in
                // 3. the LIU patch panel is the same as the massupdate
                if (listOfSelectedRowsUUID.includes(
                    HelperFunctions.getConsumerUUID(row, this.props.cutsheetType)
                ) && !!row[patchPanelA] && row[patchPanelA] === patchPanelAValue) {
                    // If valid Port value eg "5" is provided we increment each selected row. When value is removed
                    // or an invalid value is given, we update all the selected rows to become empty.
                    const updatedRow = HelperFunctions.massUpdatePorts(newValueCounter, newValue, row, column);
                    if (updatedRow.isPortValueSingleOrPair === ConstantLightHouse.PORT_VALUES_TYPES.SINGLE) {
                        newValueCounter += 1;
                        return updatedRow;
                    } else if (updatedRow.isPortValueSingleOrPair === ConstantLightHouse.PORT_VALUES_TYPES.PAIR) {
                        // We need to do this incase of the Port Type being Pair/Duplex.
                        newValueCounter += 2;
                        return updatedRow;
                    }
                    return { ...row, [column.id]: "" };
                }
                return row;
            });
        }
        this.setState({
            updatedCutsheetKeyValuePairs
        });
    }

    handleOspPortUpdates = (item, column, newValue, selectedRows) => {
        let updatedCutsheetKeyValuePairs = JSON.parse(JSON.stringify(this.state.updatedCutsheetKeyValuePairs));
        const rowIndexToUpdate = this.getRowIndexToUpdate(item, updatedCutsheetKeyValuePairs);
        // if the regex defined is not followed we return a blank
        if ((/^\d+$/.test(newValue)) || (/^\d+-\d+$/.test(newValue))) {
            updatedCutsheetKeyValuePairs[rowIndexToUpdate][column.id] = newValue;
        } else {
            updatedCutsheetKeyValuePairs[rowIndexToUpdate][column.id] = "";
        }
        // MASS UPDATE FOR THE PORTS IS DONE NEXT
        // get the value of patch panels for this port
        const aFiberHandoffValue = updatedCutsheetKeyValuePairs[rowIndexToUpdate].a_osp_panel_location;
        const zFiberHandOffValue = updatedCutsheetKeyValuePairs[rowIndexToUpdate].z_osp_panel_location;
        // first get this row's consumer primary key
        const rowUUID = HelperFunctions.getConsumerUUID(item, this.props.cutsheetType);
        // second get the UUIDs of all the selected rows
        const listOfSelectedRowsUUID = selectedRows
            .map(row => HelperFunctions.getConsumerUUID(row, this.props.cutsheetType));
        let newValueCounter = parseInt(newValue, 10);
        // if the row which is being updated is one of the selected rows
        // then we can massupdate the ports of other selected rows here
        if (listOfSelectedRowsUUID.includes(rowUUID)) {
            // if so then all the rows which are selected need to update their value to the newValue
            // this helperfunction also handles things like invalid input, for example negative
            // input must match a regex like "11-12" two numbers seperated by hyphen
            updatedCutsheetKeyValuePairs = updatedCutsheetKeyValuePairs.map((row) => {
                if ((row.a_osp_panel_location === aFiberHandoffValue || row.z_osp_panel_location === zFiberHandOffValue)
                    && listOfSelectedRowsUUID.includes(HelperFunctions.getConsumerUUID(row, this.props.cutsheetType))) {
                    // If valid Port value eg "5" or "5-6" is provided we increment each selected row.
                    // When value is removed or an invalid value is given,
                    // we update all the selected rows to become empty.
                    const updatedRow = HelperFunctions.massUpdatePorts(newValueCounter, newValue, row, column);
                    if (updatedRow.isPortValueSingleOrPair === ConstantLightHouse.PORT_VALUES_TYPES.SINGLE) {
                        newValueCounter += 1;
                    } else if (updatedRow.isPortValueSingleOrPair === ConstantLightHouse.PORT_VALUES_TYPES.PAIR) {
                        newValueCounter += 2;
                    }
                    return updatedRow;
                }
                return row;
            });
        }
        this.setState({
            updatedCutsheetKeyValuePairs
        });
    }

    handleZInterfaceUpdates = (item, column, newValue, selectedRows) => {
        let updatedCutsheetKeyValuePairs = JSON.parse(JSON.stringify(this.state.updatedCutsheetKeyValuePairs));
        const rowIndexToUpdate = this.getRowIndexToUpdate(item, updatedCutsheetKeyValuePairs);

        updatedCutsheetKeyValuePairs[rowIndexToUpdate][column.id] = newValue;

        // MASS UPDATE FOR THE PORTS IS DONE NEXT
        // first get this row's consumer primary key
        const rowUUID = HelperFunctions.getConsumerUUID(item, this.props.cutsheetType);
        // second get the UUIDs of all the selected rows
        const listOfSelectedRowsUUID = selectedRows
            .map(row => HelperFunctions.getConsumerUUID(row, this.props.cutsheetType));

        let newValueCounter = parseInt(newValue, 10);
        // if the row which is being updated is one of the selected rows
        // then we can mass update the ports of other selected rows here
        if (listOfSelectedRowsUUID.includes(rowUUID)) {
            // All the rows which are selected need to update their value to the newValue
            updatedCutsheetKeyValuePairs = updatedCutsheetKeyValuePairs.map((row) => {
                if (listOfSelectedRowsUUID.includes(HelperFunctions.getConsumerUUID(row, this.props.cutsheetType))) {
                    // If newValue is a valid port value, proceed with massUpdatePorts
                    if ((/^\d+$/.test(newValue)) || (/^\d+-\d+$/.test(newValue))) {
                        // Call massUpdatePorts
                        const updatedRow = HelperFunctions.massUpdatePorts(newValueCounter, newValue, row, column);
                        if (updatedRow.isPortValueSingleOrPair === ConstantLightHouse.PORT_VALUES_TYPES.SINGLE) {
                            newValueCounter += 1;
                        } else if (updatedRow.isPortValueSingleOrPair === ConstantLightHouse.PORT_VALUES_TYPES.PAIR) {
                            newValueCounter += 2;
                        }
                        return updatedRow;
                    }
                    // If newValue is not a valid port value, just set the column value to newValue
                    return {
                        ...row,
                        [column.id]: newValue
                    };
                }
                return row;
            });
        }
        this.setState({
            updatedCutsheetKeyValuePairs
        });
    }

    handleOtherFieldUpdates = (item, column, newValue, selectedRows) => {
        let updatedCutsheetKeyValuePairs = JSON.parse(JSON.stringify(this.state.updatedCutsheetKeyValuePairs));
        const copyOfSelectedRows = JSON.parse(JSON.stringify(selectedRows));
        const rowIndexToUpdate = this.getRowIndexToUpdate(item, updatedCutsheetKeyValuePairs);
        const rowUUID = HelperFunctions.getConsumerUUID(item, this.props.cutsheetType);
        const listOfSelectedRowsUUID = copyOfSelectedRows
            .map(row => HelperFunctions.getConsumerUUID(row, this.props.cutsheetType));
        // Update the column's value in the found row
        updatedCutsheetKeyValuePairs[rowIndexToUpdate][column.id] = newValue;

        // Apply Mass Update: Check if the selected rows have the updated row
        if (listOfSelectedRowsUUID.includes(rowUUID)) {
            // if so then all the rows which are selected need to update their value to the newValue
            updatedCutsheetKeyValuePairs = updatedCutsheetKeyValuePairs.map((row) => {
                if (listOfSelectedRowsUUID
                    .includes(HelperFunctions.getConsumerUUID(row, this.props.cutsheetType))) {
                    // Create a new object with the desired updates for the selected rows
                    return {
                        ...row,
                        [column.id]: newValue
                    };
                }
                return row;
            });
        }
        // Set the state with the updated array
        this.setState({
            updatedCutsheetKeyValuePairs
        });
    }

    handlePatchPanelAInputChangeForLiu = (item, column, newValue, selectedRows) => {
        let updatedCutsheetKeyValuePairs = JSON.parse(JSON.stringify(this.state.updatedCutsheetKeyValuePairs));
        const copyOfSelectedRows = JSON.parse(JSON.stringify(selectedRows));
        const rowIndexToUpdate = this.getRowIndexToUpdate(item, updatedCutsheetKeyValuePairs);

        // Update the column's value in the found row
        updatedCutsheetKeyValuePairs[rowIndexToUpdate][column.id] = newValue;

        // if there is a change in the patch panel A value, then we must
        // automatically put the value of the patch panel Z
        // AND
        // clear out the value of the port
        const aPatchPanel = this.state.hopOptionsDropDown
            .filter(hop => hop.id_pp_a === column.id)
            .map(result => result.id_pp_a);
        const zPatchPanel = this.state.hopOptionsDropDown
            .filter(hop => hop.id_pp_a === column.id)
            .map(result => result.id_pp_z);

        // Get the first match in ippmSearchResults for PatchPanelLocationZ
        const zValue = this.state.ippmSearchResults
            .find(result => result.PatchPanelLocationA === newValue)?.PatchPanelLocationZ;

        updatedCutsheetKeyValuePairs[rowIndexToUpdate][aPatchPanel] = newValue;
        updatedCutsheetKeyValuePairs[rowIndexToUpdate][zPatchPanel] = zValue;

        const portAColumnName = this.state.hopOptionsDropDown
            .filter(hop => hop.id_pp_a === column.id)
            .map(result => result.id_port_a);
        updatedCutsheetKeyValuePairs[rowIndexToUpdate][portAColumnName] = "";

        const portZColumnName = this.state.hopOptionsDropDown
            .filter(hop => hop.id_pp_a === column.id)
            .map(result => result.id_port_z);
        updatedCutsheetKeyValuePairs[rowIndexToUpdate][portZColumnName] = "";

        // Apply massupdate
        // first check if the selectedRows contains this row

        const rowUUID = HelperFunctions.getConsumerUUID(item, this.props.cutsheetType);
        const listOfSelectedRowsUUID = copyOfSelectedRows
            .map(row => HelperFunctions.getConsumerUUID(row, this.props.cutsheetType));

        if (listOfSelectedRowsUUID.includes(rowUUID)) {
            // if so then all the rows which are selected need to update their value to the newValue
            updatedCutsheetKeyValuePairs = updatedCutsheetKeyValuePairs.map((row) => {
                if (listOfSelectedRowsUUID
                    .includes(HelperFunctions.getConsumerUUID(row, this.props.cutsheetType))) {
                    // Create a new object with the desired updates for the selected rows
                    return {
                        ...row,
                        [column.id]: newValue,
                        [aPatchPanel]: newValue,
                        [zPatchPanel]: zValue,
                        [portAColumnName]: "",
                        [portZColumnName]: ""
                    };
                }
                return row;
            });
        }

        // Set the state with the updated array
        this.setState({
            updatedCutsheetKeyValuePairs
        });
    }

    fixKeysForMultiHops = () => {
        const updatedCutsheetKeyValuePairs = JSON.parse(JSON.stringify(this.state.updatedCutsheetKeyValuePairs));
        IspDashboardHelper.createAdditionalKeysForMultiHopTrunkHelper(updatedCutsheetKeyValuePairs);
        this.setState({
            updatedCutsheetKeyValuePairs,
            originalCutsheetKeyValuePairs: updatedCutsheetKeyValuePairs
        });
    }

    /**
    * This adds the dynamic columns needed for ISP cutsheets.
     * The columns are inserted dynamically as they are added based on the response from the backend.
     * @param(cutsheetKeyValuePairs) Response given from the backend.
     * Updates the State of updatedColumnDefinitions to account for the addtion of dynamic columns.
     */
    addAdditionalColumnsForIspCutsheets = (cutsheetKeyValuePairs) => {
        const minimumColumnDefinitions = [...this.state.originalColumnDefinitions];
        let sortedArrayOfGroups;
        if (this.props.cutsheetType === ConstantLightHouse.ISP_CUTSHEET_TYPE.CLIENT ||
            this.props.cutsheetType === ConstantLightHouse.ISP_CUTSHEET_TYPE.BACKBONE_CLIENT_MIGRATION) {
            const groups = new Set();

            cutsheetKeyValuePairs.forEach((keyValueMap) => {
                // Iterate through all key-value pairs in the current map
                Object.keys(keyValueMap).forEach((key) => {
                    if (key.startsWith("trunk_to_trunk_link_instance_id_")) {
                        const groupIdentifier = key.slice(-2);
                        groups.add(groupIdentifier);
                    }
                });
            });
            const listOfGroups = Array.from(groups);
            sortedArrayOfGroups = listOfGroups.sort((a, b) => a.localeCompare(b));

            sortedArrayOfGroups.forEach((groupIdentifier, index) => {
                const groupColumns = [
                    {
                        id: `trunk_to_trunk_link_instance_id_${groupIdentifier}`,
                        header: HelperFunctions.provideColoredHeader(`trunk_to_trunk_link_instance_id_${groupIdentifier}`, index),
                        minWidth: 176,
                        cell: item => item[`trunk_to_trunk_link_instance_id_${groupIdentifier}`],
                        isRowHeader: true
                    },
                    // Editable (link to be updated trunk_to_trunk_link_instance_id_${groupIdentifier})
                    {
                        id: `${ConstantLightHouse.ISP_ATTRIBUTES.a_dwdm_location}_${groupIdentifier}`,
                        sortingField: `${ConstantLightHouse.ISP_ATTRIBUTES.a_dwdm_location}_${groupIdentifier}`,
                        header: HelperFunctions.provideColoredHeader(
                            `${ConstantLightHouse.ISP_ATTRIBUTES.a_dwdm_location}`, index
                        ),
                        minWidth: 276,
                        downloadableValue: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.a_dwdm_location}_${groupIdentifier}`],
                        downloadableColumnHeader: `${ConstantLightHouse.ISP_ATTRIBUTES.a_dwdm_location}`,
                        cell: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.a_dwdm_location}_${groupIdentifier}`],
                        editConfig: {
                            ariaLabel: "Name",
                            editIconAriaLabel: "editable",
                            editingCell: (
                                item,
                                { currentValue, setValue }
                            ) => (
                                <Input
                                    value={currentValue}
                                    onChange={event => (
                                        setValue(event.detail.value)
                                    )}
                                    onFocus={() => setValue(item[
                                        `${ConstantLightHouse.ISP_ATTRIBUTES.a_dwdm_location}_${groupIdentifier}`])}
                                    autoFocus
                                    placeholder="Enter DWDM location"
                                />
                            )
                        },
                        isRowHeader: true
                    },
                    {
                        id: `${ConstantLightHouse.ISP_ATTRIBUTES.a_client_dwdm_port}_${groupIdentifier}`,
                        sortingField: `${ConstantLightHouse.ISP_ATTRIBUTES.a_client_dwdm_port}_${groupIdentifier}`,
                        header: HelperFunctions.provideColoredHeader(
                            `${ConstantLightHouse.ISP_ATTRIBUTES.a_client_dwdm_port}`, index
                        ),
                        minWidth: 176,
                        downloadableValue: item => item[
                            `${ConstantLightHouse.ISP_ATTRIBUTES.a_client_dwdm_port}_${groupIdentifier}`],
                        downloadableColumnHeader: `${ConstantLightHouse.ISP_ATTRIBUTES.a_client_dwdm_port}`,
                        cell: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.a_client_dwdm_port}_${groupIdentifier}`],
                        isRowHeader: true
                    },
                    {
                        id: `${ConstantLightHouse.ISP_ATTRIBUTES.a_optic_type}_${groupIdentifier}`,
                        sortingField: `${ConstantLightHouse.ISP_ATTRIBUTES.a_optic_type}_${groupIdentifier}`,
                        header: HelperFunctions.provideColoredHeader(
                            `${ConstantLightHouse.ISP_ATTRIBUTES.a_optic_type}`, index
                        ),
                        minWidth: 176,
                        downloadableValue: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.a_optic_type}_${groupIdentifier}`],
                        downloadableColumnHeader: `${ConstantLightHouse.ISP_ATTRIBUTES.a_optic_type}`,
                        cell: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.a_optic_type}_${groupIdentifier}`],
                        isRowHeader: true
                    },
                    {
                        id: `${ConstantLightHouse.ISP_ATTRIBUTES.a_trunk_dwdm_port}_${groupIdentifier}`,
                        sortingField: `${ConstantLightHouse.ISP_ATTRIBUTES.a_trunk_dwdm_port}_${groupIdentifier}`,
                        header: HelperFunctions.provideColoredHeader(
                            `${ConstantLightHouse.ISP_ATTRIBUTES.a_trunk_dwdm_port}`, index
                        ),
                        minWidth: 176,
                        downloadableValue: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.a_trunk_dwdm_port}_${groupIdentifier}`],
                        downloadableColumnHeader: `${ConstantLightHouse.ISP_ATTRIBUTES.a_trunk_dwdm_port}`,
                        cell: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.a_trunk_dwdm_port}_${groupIdentifier}`],
                        isRowHeader: true
                    },
                    {
                        id: `${ConstantLightHouse.ISP_ATTRIBUTES.a_mux_dwdm_port}_${groupIdentifier}`,
                        sortingField: `${ConstantLightHouse.ISP_ATTRIBUTES.a_mux_dwdm_port}_${groupIdentifier}`,
                        header: HelperFunctions.provideColoredHeader(
                            `${ConstantLightHouse.ISP_ATTRIBUTES.a_mux_dwdm_port}`, index
                        ),
                        minWidth: 176,
                        downloadableValue: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.a_mux_dwdm_port}_${groupIdentifier}`],
                        downloadableColumnHeader: `${ConstantLightHouse.ISP_ATTRIBUTES.a_mux_dwdm_port}`,
                        cell: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.a_mux_dwdm_port}_${groupIdentifier}`],
                        isRowHeader: true
                    },
                    {
                        id: `${ConstantLightHouse.ISP_ATTRIBUTES.a_dwdm_shelf}_${groupIdentifier}`,
                        sortingField: `a_dwdm_shelf_${groupIdentifier}`,
                        header: HelperFunctions.provideColoredHeader(
                            `${ConstantLightHouse.ISP_ATTRIBUTES.a_dwdm_shelf}`, index
                        ),
                        minWidth: 176,
                        downloadableValue: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.a_dwdm_shelf}_${groupIdentifier}`],
                        downloadableColumnHeader: `${ConstantLightHouse.ISP_ATTRIBUTES.a_dwdm_shelf}`,
                        cell: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.a_dwdm_shelf}_${groupIdentifier}`],
                        isRowHeader: true
                    },
                    {
                        id: `${ConstantLightHouse.ISP_ATTRIBUTES.wavelength}_${groupIdentifier}`,
                        sortingField: `wavelength_${groupIdentifier}`,
                        header: HelperFunctions.provideColoredHeader(
                            `${ConstantLightHouse.ISP_ATTRIBUTES.wavelength}`, index
                        ),
                        minWidth: 176,
                        downloadableValue: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.wavelength}_${groupIdentifier}`],
                        downloadableColumnHeader: `${ConstantLightHouse.ISP_ATTRIBUTES.wavelength}`,
                        cell: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.wavelength}_${groupIdentifier}`],
                        isRowHeader: true
                    },
                    {
                        id: `${ConstantLightHouse.ISP_ATTRIBUTES.z_dwdm_shelf}_${groupIdentifier}`,
                        sortingField: `${ConstantLightHouse.ISP_ATTRIBUTES.z_dwdm_shelf}_${groupIdentifier}`,
                        header: HelperFunctions.provideColoredHeader(
                            `${ConstantLightHouse.ISP_ATTRIBUTES.z_dwdm_shelf}`, index
                        ),
                        minWidth: 176,
                        downloadableValue: item => item[
                            `${ConstantLightHouse.ISP_ATTRIBUTES.z_dwdm_shelf}_${groupIdentifier}`],
                        downloadableColumnHeader: `${ConstantLightHouse.ISP_ATTRIBUTES.z_dwdm_shelf}`,
                        cell: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.z_dwdm_shelf}_${groupIdentifier}`],
                        isRowHeader: true
                    },
                    {
                        id: `${ConstantLightHouse.ISP_ATTRIBUTES.z_mux_dwdm_port}_${groupIdentifier}`,
                        sortingField: `${ConstantLightHouse.ISP_ATTRIBUTES.z_mux_dwdm_port}_${groupIdentifier}`,
                        header: HelperFunctions.provideColoredHeader(
                            `${ConstantLightHouse.ISP_ATTRIBUTES.z_mux_dwdm_port}`, index
                        ),
                        minWidth: 176,
                        downloadableValue: item => item[
                            `${ConstantLightHouse.ISP_ATTRIBUTES.z_mux_dwdm_port}_${groupIdentifier}`],
                        downloadableColumnHeader: `${ConstantLightHouse.ISP_ATTRIBUTES.z_mux_dwdm_port}`,
                        cell: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.z_mux_dwdm_port}_${groupIdentifier}`],
                        isRowHeader: true
                    },
                    {
                        id: `${ConstantLightHouse.ISP_ATTRIBUTES.z_trunk_dwdm_port}_${groupIdentifier}`,
                        sortingField: `${ConstantLightHouse.ISP_ATTRIBUTES.z_trunk_dwdm_port}_${groupIdentifier}`,
                        header: HelperFunctions.provideColoredHeader(
                            `${ConstantLightHouse.ISP_ATTRIBUTES.z_trunk_dwdm_port}`, index
                        ),
                        minWidth: 176,
                        downloadableValue: item => item[
                            `${ConstantLightHouse.ISP_ATTRIBUTES.z_trunk_dwdm_port}_${groupIdentifier}`],
                        downloadableColumnHeader: `${ConstantLightHouse.ISP_ATTRIBUTES.z_trunk_dwdm_port}`,
                        cell: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.z_trunk_dwdm_port}_${groupIdentifier}`],
                        isRowHeader: true
                    },
                    {
                        id: `${ConstantLightHouse.ISP_ATTRIBUTES.z_optic_type}_${groupIdentifier}`,
                        sortingField: `${ConstantLightHouse.ISP_ATTRIBUTES.z_optic_type}_${groupIdentifier}`,
                        header: HelperFunctions.provideColoredHeader(
                            `${ConstantLightHouse.ISP_ATTRIBUTES.z_optic_type}`, index
                        ),
                        minWidth: 176,
                        downloadableValue: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.z_optic_type}_${groupIdentifier}`],
                        downloadableColumnHeader: `${ConstantLightHouse.ISP_ATTRIBUTES.z_optic_type}`,
                        cell: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.z_optic_type}_${groupIdentifier}`],
                        isRowHeader: true
                    },
                    {
                        id: `${ConstantLightHouse.ISP_ATTRIBUTES.z_client_dwdm_port}_${groupIdentifier}`,
                        sortingField: `${ConstantLightHouse.ISP_ATTRIBUTES.z_client_dwdm_port}_${groupIdentifier}`,
                        header: HelperFunctions.provideColoredHeader(
                            `${ConstantLightHouse.ISP_ATTRIBUTES.z_client_dwdm_port}`, index
                        ),
                        minWidth: 176,
                        downloadableValue: item => item[
                            `${ConstantLightHouse.ISP_ATTRIBUTES.z_client_dwdm_port}_${groupIdentifier}`],
                        downloadableColumnHeader: `${ConstantLightHouse.ISP_ATTRIBUTES.z_client_dwdm_port}`,
                        cell: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.z_client_dwdm_port}_${groupIdentifier}`],
                        isRowHeader: true
                    },
                    // Editable (link to be updated trunk_to_trunk_link_instance_id_${groupIdentifier})
                    {
                        id: `${ConstantLightHouse.ISP_ATTRIBUTES.z_dwdm_location}_${groupIdentifier}`,
                        sortingField: `${ConstantLightHouse.ISP_ATTRIBUTES.z_dwdm_location}_${groupIdentifier}`,
                        header: HelperFunctions.provideColoredHeader(
                            `${ConstantLightHouse.ISP_ATTRIBUTES.z_dwdm_location}`, index
                        ),
                        minWidth: 276,
                        downloadableValue: item => item[
                            `${ConstantLightHouse.ISP_ATTRIBUTES.z_dwdm_location}_${groupIdentifier}`],
                        downloadableColumnHeader: `${ConstantLightHouse.ISP_ATTRIBUTES.z_dwdm_location}`,
                        cell: item => item[`${ConstantLightHouse.ISP_ATTRIBUTES.z_dwdm_location}_${groupIdentifier}`],
                        editConfig: {
                            ariaLabel: "Name",
                            editIconAriaLabel: "editable",
                            editingCell: (
                                item,
                                { currentValue, setValue }
                            ) => (
                                <Input
                                    value={currentValue}
                                    onChange={event => (
                                        setValue(event.detail.value)
                                    )}
                                    onFocus={() => setValue(item[
                                        `${ConstantLightHouse.ISP_ATTRIBUTES.z_dwdm_location}_${groupIdentifier}`])}
                                    autoFocus
                                    placeholder="Enter DWDM location"
                                />
                            )
                        },
                        isRowHeader: true
                    }
                ];
                // Index where you want to insert the groupColumns
                const insertIndex = IspDashboardHelper.columnIndexForTrunk(minimumColumnDefinitions,
                    this.props.cutsheetType);

                // Insert the groupColumns array at the specified index
                minimumColumnDefinitions.splice(insertIndex, 0, ...groupColumns);
            });
        } else {
            const groups = new Set();
            cutsheetKeyValuePairs.forEach((keyValueMap) => {
                // Iterate through all key-value pairs in the current map
                Object.keys(keyValueMap).forEach((key) => {
                    if (key.startsWith("passive_to_passive_link_instance_id_")) {
                        const groupIdentifier = key.slice(-2);
                        groups.add(groupIdentifier);
                    }
                });
            });

            const listOfGroups = Array.from(groups);
            sortedArrayOfGroups = listOfGroups.sort((a, b) => a.localeCompare(b));
            IspDashboardHelper.createNewColumnsForLineHops(minimumColumnDefinitions, sortedArrayOfGroups);
        }

        this.setState({
            hopOptionsDropDown: IspDashboardHelper
                .createAdditionalHopOptionsForDropDown(sortedArrayOfGroups, this.props.cutsheetType),
            originalColumnDefinitions: minimumColumnDefinitions,
            updatedColumnDefinitions: minimumColumnDefinitions
        });
    }

    addRemoveLiuColumns = (selectedOptions) => {
        // Case A:
        // check if existing data in any columns. those are automatically added
        // they cannot be removed.
        const requiredSelectedOptions = this.state.hopOptionsDropDown
            .filter(hop => this.state.updatedCutsheetKeyValuePairs
                .some(obj => Object.keys(obj).some(key => key.startsWith(hop.value.slice(0, 8))
                    && key.endsWith(hop.value.slice(-3))
                    && obj[key] !== "")));
        // Case B:
        // if no existing data and its selectedOptions
        // then add it to the state.updatedColumnDefinitions

        const dedupeSelectedOptions = selectedOptions.filter(selectedOption =>
            !requiredSelectedOptions.some(requiredOption => requiredOption.id === selectedOption.id));

        const allSelectedOptions = [...requiredSelectedOptions, ...dedupeSelectedOptions];

        const hopItems = [];

        // finally loop thru all the requiredSelectedOptions
        // and create the cells for them
        allSelectedOptions.forEach((selectedOption) => {
            const patchPanelAutosuggest = {
                id: selectedOption.id_pp_a,
                sortingField: selectedOption.id_pp_a,
                header: selectedOption.labelA,
                minWidth: 275,
                downloadableValue: item => item[selectedOption.id_pp_a],
                downloadableColumnHeader: selectedOption.labelA,
                cell: item => (
                    <div style={{ background: selectedOption.color, padding: "7px" }}>
                        {!!item[selectedOption.id_pp_a_error] &&
                            <StatusIndicator type="error"/>
                        }
                        {item[selectedOption.value] || item[selectedOption.id_pp_a]}
                    </div>
                ),
                isRowHeader: true,
                editConfig: {
                    ariaLabel: "Name",
                    editIconAriaLabel: "editable",
                    errorIconAriaLabel: "Name Error",
                    submittingEditText: "Success",
                    enteredTextLabel: "Enter text and hit Enter to search",
                    validation: item => item[selectedOption.id_pp_a_error],
                    editingCell: (
                        item,
                        { currentValue, setValue }
                    ) => (
                        <Autosuggest
                            autoFocus
                            disableBrowserAutocorrect
                            value={HelperFunctions.ensureValueNotNull(currentValue)}
                            onChange={({ detail }) => setValue(detail.value)}
                            onSelect={({ detail }) => setValue(detail.value)}
                            onFocus={() => setValue(item[selectedOption.id_pp_a])}
                            disabled={IspDashboardHelper.disableAutosuggest(selectedOption.id_pp_a, item)}
                            options={this.state.patchPanelALocationsSelect}
                            empty="Type in patch panel location and hit ENTER to search"
                            placeholder="Search for patch panel location"
                            expandToViewport
                        />
                    )
                }
            };

            const portInputA = {
                id: selectedOption.id_port_a,
                sortingField: selectedOption.id_port_a,
                header: selectedOption.labelPort,
                minWidth: 100,
                downloadableValue: item => item[selectedOption.id_port_a],
                downloadableColumnHeader: selectedOption.labelPort,
                cell: item => (
                    <div>
                        <div style={{
                            background: selectedOption.color,
                            padding: "7px"
                        }}
                        >
                            {!!item[selectedOption.id_port_a_error] &&
                                <StatusIndicator type="error"/>
                            }
                            {item[selectedOption.id_port_a]}
                        </div>
                    </div>
                ),
                isRowHeader: true,
                editConfig: {
                    ariaLabel: "Name",
                    editIconAriaLabel: "editable",
                    errorIconAriaLabel: "editable",
                    validation: item => item[selectedOption.id_port_a_error],
                    editingCell: (
                        item,
                        { currentValue, setValue }
                    ) => (
                        <Input
                            value={currentValue}
                            onChange={event => (
                                setValue(event.detail.value)
                            )}
                            onFocus={() => setValue(item[selectedOption.id_port_a])}
                            autoFocus
                            expandToViewport
                            disabled={!item[selectedOption.id_pp_a]}
                            placeholder={!item[selectedOption.value] ?
                                "Enter patch panel A location first" :
                                "Enter port for patch panel"}
                        />
                    )
                }
            };

            const portInputZ = {
                id: selectedOption.id_port_z,
                sortingField: selectedOption.id_port_z,
                header: selectedOption.labelPort,
                minWidth: 100,
                downloadableValue: item => item[selectedOption.id_port_z],
                downloadableColumnHeader: selectedOption.labelPort,
                cell: item => (
                    <div>
                        <div style={{
                            background: selectedOption.color,
                            padding: "7px"
                        }}
                        >
                            {!!item[selectedOption.id_port_z_error] &&
                                <StatusIndicator type="error"/>
                            }
                            {item[selectedOption.id_port_z]}
                        </div>
                    </div>
                ),
                isRowHeader: true,
                editConfig: {
                    ariaLabel: "Name",
                    editIconAriaLabel: "editable",
                    errorIconAriaLabel: "editable",
                    validation: item => item[selectedOption.id_port_z_error],
                    editingCell: (
                        item,
                        { currentValue, setValue }
                    ) => (
                        <Input
                            value={currentValue}
                            onChange={event => (
                                setValue(event.detail.value)
                            )}
                            onFocus={() => setValue(item[selectedOption.id_port_z])}
                            autoFocus
                            expandToViewport
                            disabled={!item[selectedOption.id_pp_a]}
                            placeholder={!item[selectedOption.value] ?
                                "Enter patch panel A location first" :
                                "Enter port for patch panel"}
                        />
                    )
                }
            };

            const patchPanelZ = {
                id: selectedOption.id_pp_z,
                sortingField: selectedOption.id_pp_z,
                header: selectedOption.labelZ,
                minWidth: 150,
                downloadableValue: item => item[selectedOption.id_pp_z],
                downloadableColumnHeader: selectedOption.labelZ,
                cell: item => (
                    <div style={{ background: selectedOption.color, padding: "7px" }}>
                        {item[selectedOption.id_pp_z]}
                    </div>
                ),
                isRowHeader: true
            };

            // Add the new hopOption to hopItems
            hopItems.push(patchPanelAutosuggest);
            hopItems.push(portInputA);
            hopItems.push(portInputZ);
            hopItems.push(patchPanelZ);
        });

        // Create a copy of originalColumnDefinitions
        const updatedColumnDefinitions = [...this.state.originalColumnDefinitions];

        // Add the new hopOption to newColumnDefinitions
        // Insert the sorted combined hop items at a specific position (index 2)
        IspDashboardHelper.updateColumnsAfterAddingLius(updatedColumnDefinitions, this.props.cutsheetType, hopItems);
        this.setState({
            updatedColumnDefinitions
        });
    }

    /**
     * Executes batchPutLinks API
     * @returns {Promise<void>}
     */
    submitUpdates = async () => {
        try {
            // Validate all the OSP rows
            const updatedCutsheetKeyValuePairs =
                JSON.parse(JSON.stringify(this.state.updatedCutsheetKeyValuePairs));
            OspDashboardHelper.validateRows(updatedCutsheetKeyValuePairs);
            const ospError = updatedCutsheetKeyValuePairs.some(row => row.validation_error);

            // Validate all types of ISP cutsheet rows
            const ispError = this.props.cutsheetType === ConstantLightHouse.ISP_CUTSHEET_TYPE.INTERNAL_CABLING
                ? IspDashboardHelper.validateMmrData(
                    updatedCutsheetKeyValuePairs,
                    this.state.originalCutsheetKeyValuePairs
                )
                : IspDashboardHelper.validateIspRows(
                    updatedCutsheetKeyValuePairs,
                    this.state.ippmSearchResults
                );

            this.setState({
                updatedCutsheetKeyValuePairs
            });

            if (ispError || ospError) {
                throw new Error(`Submission failed due to invalid data present in the cutsheet, see error details on individual cells.
                    Remember to clear any filters to see hidden rows.`);
            }

            this.setState({
                submissionInProgress: true
            });

            // create batch links for the update attributes
            const linksToBatchPut = { Links: [] };
            IspDashboardHelper.attributeSubmitHelper(
                linksToBatchPut,
                this.state.updatedCutsheetKeyValuePairs,
                this.state.originalCutsheetKeyValuePairs,
                this.state.updatedColumnDefinitions
            );

            const liuLinksToBatchPut = { Links: [] };
            const liuLinksToBatchDelete = [];

            this.state.updatedCutsheetKeyValuePairs.forEach((row, index) => {
                Object.keys(row).forEach((key) => {
                    // this helper function is meant to be used on a single row of the Table
                    // it will determine whether an LIU has been updated or added to a specific row
                    // it will take into account which hop the LIU is make it an individual link
                    // one row can have up to 10 LIUs
                    // it will also add the linkId for deletion for an deconsumed LIU
                    this.liuSubmitHelper(key, row, index, liuLinksToBatchPut, liuLinksToBatchDelete);
                });
            });

            if (liuLinksToBatchDelete.length > 0) {
                await this.linkServiceBackendClient.batchDeleteLinks(liuLinksToBatchDelete);
            }

            // Chunkify the output for liuLinkToBatchPut
            let chunks = MangoHelperFunctions.chunkifyList(liuLinksToBatchPut.Links, 16);
            await Promise.all(chunks.map(chunk => this.linkServiceBackendClient.batchPutLinks(chunk)));

            // Chunkify the output for linksToBatchPut
            chunks = MangoHelperFunctions.chunkifyList(linksToBatchPut.Links, 16);
            await Promise.all(chunks.map(chunk => this.linkServiceBackendClient.batchPutLinks(chunk)));

            const handleThirdPartyLinksChange = () => {
                this.setState({
                    handleThirdPartyLinks: true
                });
            };

            // Execute MMR update only for INTERNAL_CABLING cutsheet type
            if (this.props.cutsheetType === ConstantLightHouse.ISP_CUTSHEET_TYPE.INTERNAL_CABLING) {
                const mmrEndName = { endNames: [] };
                IspDashboardHelper.mmrDataSubmitHelper(
                    mmrEndName,
                    this.state.updatedCutsheetKeyValuePairs,
                    this.state.originalCutsheetKeyValuePairs,
                    handleThirdPartyLinksChange
                );

                if (mmrEndName.endNames.length) {
                    const endNameChunks = MangoHelperFunctions.chunkifyList(mmrEndName.endNames, 5);
                    await Promise.all(
                        endNameChunks.map(chunk =>
                            Promise.all(
                                chunk.map(endName =>
                                    this.linkServiceBackendClient.updateLinkEnds(
                                        endName.instanceId,
                                        [endName.mmrAEndName, endName.mmrZEndName],
                                        this.state.handleThirdPartyLinks
                                    ))
                            ))
                    );
                }
            }

            // This will make backend calls for OSP. It is not clubbed together with the ISP calls above by design.
            // Basically, we're defining all ISP as the first user-action and all OSP as second user-action. Each action
            // needs to be atomic.
            await OspDashboardHelper.processRows(
                this.linkServiceBackendClient,
                this.state.updatedCutsheetKeyValuePairs,
                this.state.originalCutsheetKeyValuePairs,
                this.state.panelLocationWithPortsToLinkMap,
            );

            this.setState({
                submissionInProgress: false,
                readyToSubmitLiuInformation: false,
                notificationToDisplay:
                    HelperFunctions.generateSuccessMessageForFlashbar(
                        "Successfully submitted", "", true, this
                    )
            });

            await this.executeGetIsp();
        } catch (e) {
            this.setState({
                submissionInProgress: false,
                notificationToDisplay:
                    HelperFunctions.generateErrorMessageForFlashbar(
                        "Submission failed.",
                        e.toString(),
                        true,
                        this
                    )
            });
        }
    }


    liuSubmitHelper = (key, row, index, liuLinksToBatchPut, liuLinksToBatchDelete) => {
        if (key.match(/^[a-z]{1,2}_hop_[1-5]_isp_panel_a_location_.*$/)) {
            const attributes = [];
            const typeId =
                LinkHierarchyHelper.getTypeIdFromLink(Constants.LINK_TYPES.lightInterfaceUnit);
            // Extract the hop position from the key
            const match = key.match(/^([a-z]{1,2})_hop_(\d+)_\w+_(\w+)$/);

            // eslint-disable-next-line
            const [_, type, hopPosition, parentLinkType] = match;

            // Get the values for the keys
            const panelALocationValue = row[`${type}_hop_${hopPosition}_isp_panel_a_location_${parentLinkType}`];
            const panelZLocationValue = row[`${type}_hop_${hopPosition}_isp_panel_z_location_${parentLinkType}`];
            const liuLinkId = row[`${type}_hop_${hopPosition}_liu_link_instance_id_${parentLinkType}`];
            const panelAPortValue = row[`${type}_hop_${hopPosition}_isp_panel_a_port_${parentLinkType}`];
            const panelZPortValue = row[`${type}_hop_${hopPosition}_isp_panel_z_port_${parentLinkType}`];
            const consumers = JSON.stringify(
                [`${Constants.LINK_INSTANCE_ID_PATTERN}${
                    IspDashboardHelper.getLiuParentUUID(row, parentLinkType)}`]
            );

            const previousPanelALocationValue =
                this.state.originalCutsheetKeyValuePairs[index][`${type}_hop_${hopPosition}_isp_panel_a_location_${parentLinkType}`];
            const previousPanelAPortValue =
                this.state.originalCutsheetKeyValuePairs[index][`${type}_hop_${hopPosition}_isp_panel_a_port_${parentLinkType}`];
            const previousPanelZPortValue =
                this.state.originalCutsheetKeyValuePairs[index][`${type}_hop_${hopPosition}_isp_panel_z_port_${parentLinkType}`];

            // Collect the attributes and build the link object
            const panelALocationAttribute =
                HelperFunctions.createAttribute("isp_panel_a_location", panelALocationValue);
            const panelZLocationAttribute =
                HelperFunctions.createAttribute("isp_panel_z_location", panelZLocationValue);
            const panelAPortAttribute =
                HelperFunctions.createAttribute("isp_panel_a_port", panelAPortValue);
            const panelZPortAttribute =
                HelperFunctions.createAttribute("isp_panel_z_port", panelZPortValue);
            const hopPositionAttribute = HelperFunctions.createAttribute("hop_position", hopPosition);
            const consumersAttribute = HelperFunctions.createAttribute("consumers", consumers);
            const dataCenterSideAttribute = HelperFunctions.createAttribute("data_center_side", type);

            // Add the attributes to the 'attributes' array
            attributes.push(panelALocationAttribute, panelZLocationAttribute,
                panelAPortAttribute, panelZPortAttribute, hopPositionAttribute,
                dataCenterSideAttribute, consumersAttribute);
            // Create the link object and push it to the 'Links' array
            const link = { typeId, attributes };

            // only update here if it is an update to originalCutSheetKeyValuePairs
            if (previousPanelALocationValue !== panelALocationValue ||
                previousPanelAPortValue !== panelAPortValue ||
                previousPanelZPortValue !== panelZPortValue
                || !previousPanelALocationValue
            ) {
                // We need to check if the panelALocationValue has been cleared out,
                // which is just a simple delete
                if (panelALocationValue !== "") {
                    liuLinksToBatchPut.Links.push(link);
                }
                // if the link is being updated, then delete the old one too
                if (liuLinkId) {
                    liuLinksToBatchDelete.push(
                        { instanceId: liuLinkId }
                    );
                }
            }
        }
    }

    handleColumnNameChange = async () => {
        const isDxView = !this.state.handleColumnName;

        const newColumnDefinitions = isDxView
            ? IspDashboardHelper.UpdateDxView([...this.state.updatedColumnDefinitions])
            : IspDashboardHelper.updateColumnVisibility(
                [...this.state.originalColumnDefinitions],
                this.props.cutsheetType
            );

        // Save DX view state preference
        IspDashboardHelper.setDxViewState(this.props.cutsheetType, isDxView);

        this.setState({
            updatedColumnDefinitions: newColumnDefinitions,
            handleColumnName: isDxView
        });
    }

    render() {
        return (
            <div>
                <Flashbar items={this.state.notificationToDisplay}/>
                <LightHouseHeader
                    history={this.props.history}
                    flashbarType={this.state.flashbar.type}
                    flashbarText={this.state.flashbar.text}
                    onDismiss={this.handleFlashbarClose}
                    auth={this.props.auth}
                    sideNavError={this.props.sideNavError}
                />
                <div className={Constants.FREMONT_PAGE_WIDTH_CLASS}>
                    <SpaceBetween direction="vertical" size={Constants.PADDING_SIZES.SPACE_BETWEEN_SECTIONS}>
                        {this.props.cutsheetType === ConstantLightHouse.ISP_CUTSHEET_TYPE.CLIENT &&
                            <div>
                                <OspPanelLocationSearchHandler
                                    isAOspPanelLocationCellClicked={false}
                                    onCopyToSelectedRowsClick={this.handleCopyToSelectedRows}
                                    getOSPPanelLocations={this.getOSPPanelLocations}
                                    updatedCutsheetKeyValuePairs={this.state.updatedCutsheetKeyValuePairs}
                                    ospPanelLocationResults={this.state.ospPanelLocationResults}
                                    setIsInitialSearch={val => this.setState({ isInitialSearch: val })}
                                    isInitialSearch={this.state.isInitialSearch}
                                    setIsOspLocationEditDisabled={val =>
                                        this.setState({ isOspLocationEditDisabled: val })}
                                />
                            </div>
                        }
                        <IspCutsheetTableV2
                            ref={this.ispCutsheetTableRef}
                            items={this.state.updatedCutsheetKeyValuePairs}
                            cutsheetRecordNum={MangoHelperFunctions.getTotalRecordHeader(
                                this.state.updatedCutsheetKeyValuePairs.length
                            )}
                            addLiuColumnToCutsheet={this.addLiuColumnToCutsheet}
                            addRemoveLiuColumns={this.addRemoveLiuColumns}
                            isDownloadEnabled
                            disabled={this.state.disabled}
                            cutsheetType={this.props.cutsheetType}
                            updatedColumnDefinitions={this.state.updatedColumnDefinitions}
                            handlePatchPanelAInputChangeForLiu={this.handlePatchPanelAInputChangeForLiu}
                            handlePortAInputChangeForLiu={this.handlePortAInputChangeForLiu}
                            getIPPMInformation={this.getIPPMInformation}
                            submissionInProgress={this.state.submissionInProgress}
                            isSearchInProgress={this.state.isLiuSearchInProgress}
                            submitUpdates={this.submitUpdates}
                            handleUpdateAttribute={this.handleUpdateAttribute}
                            handleZInterfaceUpdates={this.handleZInterfaceUpdates}
                            readyToSubmitLiuInformation={this.state.readyToSubmitLiuInformation}
                            handleOspPortUpdates={this.handleOspPortUpdates}
                            handleOtherFieldUpdates={this.handleOtherFieldUpdates}
                            hopOptionsDropDown={this.state.hopOptionsDropDown}
                            handleOnClickEditOSPLocation={this.handleOnClickEditOSPLocation}
                            searchTerm={this.props.searchTerm}
                            isOspLocationEditDisabled={this.state.isOspLocationEditDisabled}
                            handleColumnNameChange={this.handleColumnNameChange}
                            handleColumnName={this.state.handleColumnName}
                        />
                    </SpaceBetween>
                </div>
            </div>
        );
    }
}