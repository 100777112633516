export default class Constants {
    static FREMONT_PAGE_WIDTH_CLASS = "fremont-page-width";
    static LINK_INSTANCE_ID_PATTERN = "amzn1.network.link.instance.";
    static SEPARATOR = "___";
    static FLASHBAR_TYPES = {
        success: "success",
        error: "error",
        info: "info",
        warning: "warning"
    };

    static LIGHTHOUSE_ROUTES = {
        ispPatchPanelManagement: "/patchpanels"
    }

    // Paddings for LightHouse
    static PADDING_SIZES = {
        BOX_PAGE_PADDING: "xxl",
        SPACE_BETWEEN_CONTAINER_PADDING: "l",
        SPACE_BETWEEN_SECTIONS: "m",
        SPACE_BETWEEN_STAGES: "s",
        SPACE_BETWEEN_BUTTON_PADDING: "s",
        SPACE_BETWEEN_BUTTON_PADDING_XS: "xs",
        SPACE_BETWEEN_LINK_PADDING: "xxxs"
    };

    static COMPONENT_CONSTANTS = {
        BOX_PAGE_PADDING: "xxl",
        SPACE_BETWEEN_CONTAINER_PADDING: "l",
        SPACE_BETWEEN_STAGES: "s",
        SPACE_BETWEEN_BUTTON_PADDING: "s",
        SPACE_BETWEEN_BUTTON_PADDING_XS: "xs",
        SPACE_BETWEEN_LINK_PADDING: "xxxs"
    };

    static PATCH_PANEL_CONFIG_FIELDS = {
        ID: "PanelId",
        LOCATION_A: "PatchPanelLocationA",
        LOCATION_Z: "PatchPanelLocationZ",
        PORT_COUNT: "PortCount",
        STARTING_PORT_A: "StartingPortLocationA",
        STARTING_PORT_Z: "StartingPortLocationZ",
        BYPASS_VALIDATIONS: "BypassValidations"
    };

    static ATTRIBUTES_TYPES = {
        boolean: "Boolean",
        integer: "Integer",
        string: "String"
    }

    static CONSUMPTION_ATTRIBUTES = {
        consumesList: "consumes",
        consumerList: "consumers"
    };

    static ISP_CUTSHEET_TYPE = {
        LINE: "LINE",
        CLIENT: "CLIENT",
        INTERNAL_CABLING: "INTERNAL-CABLING",
        BACKBONE_CLIENT_MIGRATION: "BACKBONE-CLIENT-MIGRATION"
    }

    static ADVANCED_SORTING_IDS = {
        site_a_bricks_then_odd_to_even_router_then_odd_to_even_ports:
            "site_a_bricks_then_odd_to_even_router_then_odd_to_even_ports",
        site_z_bricks_then_odd_to_even_router_then_odd_to_even_ports:
            "site_z_bricks_then_odd_to_even_router_then_odd_to_even_ports"
    }

    static ISP_ATTRIBUTES = {
        a_hostname: "a_hostname",
        a_interface: "a_interface",
        a_connector_type: "a_connector_type",
        a_client_dwdm_port: "a_client_dwdm_port",
        a_client_lever_interface: "a_client_lever_interface",
        a_client_lever_optic_type: "a_client_lever_optic_type",
        a_dwdm_location: "a_dwdm_location",
        a_dwdm_shelf: "a_dwdm_shelf",
        a_fiber_type: "a_fiber_type",
        a_lever_hostname: "a_lever_hostname",
        a_lever_location: "a_lever_location",
        a_line_dwdm_device: "a_line_dwdm_device",
        a_line_dwdm_device_location: "a_line_dwdm_device_location",
        a_line_dwdm_port_1: "a_line_dwdm_port_1",
        a_line_dwdm_port_2: "a_line_dwdm_port_2",
        a_location: "a_location",
        a_mux_dwdm_port: "a_mux_dwdm_port",
        a_optic_type: "a_optic_type",
        a_ops_dwdm_port: "a_ops_dwdm_port",
        a_osp_panel_location: "a_osp_panel_location",
        a_osp_panel_ports: "a_osp_panel_ports",
        a_pluggable: "a_pluggable",
        a_port_speed: "a_port_speed",
        a_cage: "a_cage",
        a_patch_panel: "a_patch_panel",
        a_rack: "a_rack",
        a_rfts_filter: "a_rfts_filter",
        a_rfts_in_port_1: "a_rfts_in_port_1",
        a_rfts_in_port_2: "a_rfts_in_port_2",
        a_rfts_out_port_1: "a_rfts_out_port_1",
        a_rfts_out_port_2: "a_rfts_out_port_2",
        a_trunk_dwdm_port: "a_trunk_dwdm_port",
        a_trunk_lever_interface: "a_trunk_lever_interface",
        a_trunk_lever_optic_type: "a_trunk_lever_optic_type",
        comment: "comment",
        encryption_to_encryption_link_instance_id: "encryption_to_encryption_link_instance_id",
        fiber_path: "fiber_path",
        intra_dc_router_to_intra_dc_router_link_instance_id: "intra_dc_router_to_intra_dc_router_link_instance_id",
        mux_to_mux_link_instance_id: "mux_to_mux_link_instance_id",
        router_to_router_link_instance_id: "router_to_router_link_instance_id",
        wavelength: "wavelength",
        z_hostname: "z_hostname",
        z_interface: "z_interface",
        z_connector_type: "z_connector_type",
        z_client_dwdm_port: "z_client_dwdm_port",
        z_client_lever_interface: "z_client_lever_interface",
        z_client_lever_optic_type: "z_client_lever_optic_type",
        z_dwdm_location: "z_dwdm_location",
        z_dwdm_shelf: "z_dwdm_shelf",
        z_fiber_type: "z_fiber_type",
        z_lever_hostname: "z_lever_hostname",
        z_lever_location: "z_lever_location",
        z_line_dwdm_device: "z_line_dwdm_device",
        z_line_dwdm_device_location: "z_line_dwdm_device_location",
        z_line_dwdm_port_1: "z_line_dwdm_port_1",
        z_line_dwdm_port_2: "z_line_dwdm_port_2",
        z_mux_dwdm_port: "z_mux_dwdm_port",
        z_location: "z_location",
        z_ops_dwdm_port: "z_ops_dwdm_port",
        z_optic_type: "z_optic_type",
        z_osp_panel_location: "z_osp_panel_location",
        z_osp_panel_ports: "z_osp_panel_ports",
        z_trunk_dwdm_port: "z_trunk_dwdm_port",
        z_pluggable: "z_pluggable",
        z_port_speed: "z_port_speed",
        z_cage: "z_cage",
        z_patch_panel: "z_patch_panel",
        z_rack: "z_rack",
        z_rfts_filter: "z_rfts_filter",
        z_rfts_in_port_1: "z_rfts_in_port_1",
        z_rfts_in_port_2: "z_rfts_in_port_2",
        z_rfts_out_port_1: "z_rfts_out_port_1",
        z_rfts_out_port_2: "z_rfts_out_port_2",
        z_trunk_lever_interface: "z_trunk_lever_interface",
        z_trunk_lever_optic_type: "z_trunk_lever_optic_type"
    }

    static MMR_ATTRIBUTES = {
        MMR_cage: "MMR_cage",
        MMR_patch_panel: "MMR_patch_panel",
        MMR_rack: "MMR_rack",
        MMR_interface: "MMR_interface"
    }

    static MMR_INFO_PREFIX = "MMR-info-";

    static DX_COLUMN_MAPPINGS = {
        [this.ISP_ATTRIBUTES.z_cage]: this.MMR_ATTRIBUTES.MMR_cage,
        [this.ISP_ATTRIBUTES.z_patch_panel]: this.MMR_ATTRIBUTES.MMR_patch_panel,
        [this.ISP_ATTRIBUTES.z_rack]: this.MMR_ATTRIBUTES.MMR_rack,
        [this.ISP_ATTRIBUTES.z_interface]: this.MMR_ATTRIBUTES.MMR_interface
    };


    static EDITABLE_ISP_ATTRIBUTES_WITH_AUTO_SAVE = [
        this.ISP_ATTRIBUTES.comment,
        this.ISP_ATTRIBUTES.a_location,
        this.ISP_ATTRIBUTES.z_location,
        this.ISP_ATTRIBUTES.a_lever_location,
        this.ISP_ATTRIBUTES.z_lever_location,
        this.ISP_ATTRIBUTES.a_osp_panel_location,
        this.ISP_ATTRIBUTES.z_osp_panel_location,
        this.ISP_ATTRIBUTES.a_osp_panel_ports,
        this.ISP_ATTRIBUTES.z_osp_panel_ports,
        this.ISP_ATTRIBUTES.z_line_dwdm_device_location,
        this.ISP_ATTRIBUTES.a_line_dwdm_device_location,
        this.ISP_ATTRIBUTES.z_cage,
        this.ISP_ATTRIBUTES.z_patch_panel,
        this.ISP_ATTRIBUTES.z_rack
    ]

    static PLACEHOLDER_TEXT_FOR_CELL = {
        enter_comment: "Enter comment",
        enter_a_location: "Enter A Location",
        enter_z_location: "Enter Z Location",
        enter_a_lever_location: "Enter A Lever Location",
        enter_z_lever_location: "Enter Z Lever Location",
        enter_consumed_passive_patch_panel: "Enter Consumed Passive Patch Panel",
        enter_consumed_passive_port: "Enter Consumed Passive Port",
        enter_dwdm_location: "Enter DWDM Location",
        enter_z_cage: "Enter Z Cage",
        enter_z_patch_panel: "Enter Z Patch Panel",
        enter_z_rack: "Enter Z Rack",
        enter_z_interface: "Enter Z Interface"
    }

    static ISP_ATTRIBUTES_TO_PLACEHOLDER_MAP = {
        [this.ISP_ATTRIBUTES.comment]: this.PLACEHOLDER_TEXT_FOR_CELL.enter_comment,
        [this.ISP_ATTRIBUTES.a_location]: this.PLACEHOLDER_TEXT_FOR_CELL.enter_a_location,
        [this.ISP_ATTRIBUTES.z_location]: this.PLACEHOLDER_TEXT_FOR_CELL.enter_z_location,
        [this.ISP_ATTRIBUTES.a_lever_location]: this.PLACEHOLDER_TEXT_FOR_CELL.enter_a_lever_location,
        [this.ISP_ATTRIBUTES.z_lever_location]: this.PLACEHOLDER_TEXT_FOR_CELL.enter_z_lever_location,
        [this.ISP_ATTRIBUTES.a_osp_panel_location]: this.PLACEHOLDER_TEXT_FOR_CELL.enter_consumed_passive_patch_panel,
        [this.ISP_ATTRIBUTES.z_osp_panel_location]: this.PLACEHOLDER_TEXT_FOR_CELL.enter_consumed_passive_patch_panel,
        [this.ISP_ATTRIBUTES.a_osp_panel_ports]: this.PLACEHOLDER_TEXT_FOR_CELL.enter_consumed_passive_port,
        [this.ISP_ATTRIBUTES.z_osp_panel_ports]: this.PLACEHOLDER_TEXT_FOR_CELL.enter_consumed_passive_port,
        [this.ISP_ATTRIBUTES.z_line_dwdm_device_location]: this.PLACEHOLDER_TEXT_FOR_CELL.enter_dwdm_location,
        [this.ISP_ATTRIBUTES.a_line_dwdm_device_location]: this.PLACEHOLDER_TEXT_FOR_CELL.enter_dwdm_location,
        [this.ISP_ATTRIBUTES.z_cage]: this.PLACEHOLDER_TEXT_FOR_CELL.enter_z_cage,
        [this.ISP_ATTRIBUTES.z_patch_panel]: this.PLACEHOLDER_TEXT_FOR_CELL.enter_z_patch_panel,
        [this.ISP_ATTRIBUTES.z_rack]: this.PLACEHOLDER_TEXT_FOR_CELL.enter_z_rack,
        [this.ISP_ATTRIBUTES.z_interface]: this.PLACEHOLDER_TEXT_FOR_CELL.enter_z_interface
    };

    static getPlaceholderText(attribute) {
        return this.ISP_ATTRIBUTES_TO_PLACEHOLDER_MAP[attribute] || "";
    }


    static ISP_TABLE_PRIMARY_KEYS = {
        CLIENT: this.ISP_ATTRIBUTES.router_to_router_link_instance_id,
        LINE: this.ISP_ATTRIBUTES.mux_to_mux_link_instance_id,
        INTERNAL_CABLING: this.ISP_ATTRIBUTES.intra_dc_router_to_intra_dc_router_link_instance_id
    }

    static PORT_VALUES_TYPES = {
        SINGLE: "single",
        PAIR: "pair"
    }

    static OSP_PANEL_LOCATION_PARAMETER_OPTIONS = [
        {
            label: "A Site Code",
            id: "aSiteCode",
            styxName: "styx_%s_site_code",
            disabled: true
        },
        {
            label: "Z Site Code",
            id: "zSiteCode",
            styxName: "styx_%s_site_code",
            disabled: true
        },
        {
            label: "Room Id",
            id: "roomId",
            styxName: "styx_%s_room_id",
            disabled: false
        },
        {
            label: "Rack Id",
            id: "rackId",
            styxName: "styx_%s_rack_id",
            disabled: false
        },
        {
            label: "Panel Id",
            id: "panelId",
            styxName: "styx_%s_panel_id",
            disabled: false
        }
    ];

    static INPUT_TYPES = {
        searchParams: "searchParams"
    }

    static DEFAULT_HIDDEN_COLUMNS = [
        this.ISP_ATTRIBUTES.router_to_router_link_instance_id,
        this.ISP_ATTRIBUTES.encryption_to_encryption_link_instance_id,
        this.ISP_ATTRIBUTES.mux_to_mux_link_instance_id,
        this.ISP_ATTRIBUTES.intra_dc_router_to_intra_dc_router_link_instance_id
    ];

    static DX_HIDDEN_COLUMNS = [
        this.ISP_ATTRIBUTES.a_optic_type,
        this.ISP_ATTRIBUTES.a_connector_type,
        this.ISP_ATTRIBUTES.a_fiber_type,
        this.ISP_ATTRIBUTES.a_pluggable,
        this.ISP_ATTRIBUTES.z_pluggable,
        this.ISP_ATTRIBUTES.z_port_speed,
        this.ISP_ATTRIBUTES.z_fiber_type,
        this.ISP_ATTRIBUTES.z_optic_type,
        this.ISP_ATTRIBUTES.z_hostname,
        this.ISP_ATTRIBUTES.intra_dc_router_to_intra_dc_router_link_instance_id
    ]

    static TABLE_PREFERENCES = {
        DEFAULT: {
            pageSize: 100,
            wrapLines: true,
            stickyColumns: { first: 3, last: 2 },
            custom: false
        },
        DX_VIEW: {
            pageSize: 100,
            wrapLines: true,
            stickyColumns: { first: 2, last: 4 },
            custom: false
        },
        CUTSHEET_SPECIFIC: {
            [Constants.ISP_CUTSHEET_TYPE.LINE]: {
                pageSize: 100,
                wrapLines: true,
                stickyColumns: { first: 0, last: 0 },
                custom: false
            },
            [Constants.ISP_CUTSHEET_TYPE.CLIENT]: this.DEFAULT,
            [Constants.ISP_CUTSHEET_TYPE.INTERNAL_CABLING]: {
                pageSize: 100,
                wrapLines: true,
                stickyColumns: { first: 2, last: 2 },
                custom: false
            },
            [Constants.ISP_CUTSHEET_TYPE.BACKBONE_CLIENT_MIGRATION]: this.DEFAULT
        }
    }

    static SORT_BY_INT_COLUMNS = [
        this.ISP_ATTRIBUTES.a_interface,
        this.ISP_ATTRIBUTES.a_osp_panel_ports,
        this.ISP_ATTRIBUTES.z_interface,
        this.ISP_ATTRIBUTES.z_osp_panel_ports
    ]

    static KEYS_TO_CHECK = {
        MUX_TO_MUX: [
            this.ISP_ATTRIBUTES.a_line_dwdm_device_location,
            this.ISP_ATTRIBUTES.z_line_dwdm_device_location,
            this.ISP_ATTRIBUTES.comment
        ],
        ENCRYPTION_TO_ENCRYPTION: [
            this.ISP_ATTRIBUTES.a_lever_location,
            this.ISP_ATTRIBUTES.z_lever_location
        ],
        INTRA_DC_ROUTER_TO_INTRA_DC_ROUTER: [
            this.ISP_ATTRIBUTES.z_cage,
            this.ISP_ATTRIBUTES.z_rack,
            this.ISP_ATTRIBUTES.z_patch_panel,
            this.ISP_ATTRIBUTES.z_interface
        ],
        INTRA_DC_ROUTER_TO_INTRA_DC_ROUTER_ATTRIBUTES: [
            this.ISP_ATTRIBUTES.z_cage,
            this.ISP_ATTRIBUTES.z_rack,
            this.ISP_ATTRIBUTES.z_patch_panel
        ],
        ROUTER_TO_ROUTER: [
            this.ISP_ATTRIBUTES.a_location,
            this.ISP_ATTRIBUTES.z_location
        ],
        TRUNK_TO_TRUNK: [
            this.ISP_ATTRIBUTES.a_dwdm_location,
            this.ISP_ATTRIBUTES.z_dwdm_location
        ]
    };
}