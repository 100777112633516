import React from "react";
import { Link } from "@amzn/awsui-components-react/polaris";
import Constants from "../utils/Constants";
import HelperFunctions from "../common/HelperFunctions";

export default function LinkDescriptionListItem({ link }) {
    const userProvidedId = HelperFunctions.getValueFromRecordAttributes(
        link, Constants.ATTRIBUTES.user_provided_id
    );

    return (
        <li key={link.instanceId}>
            <Link
                target="_blank"
                href={HelperFunctions.buildUrl(`/linkDetails/${link.instanceId}`)}
            >
                {userProvidedId || link.instanceId}
                {link.readableLinkType ? ` (${link.readableLinkType})` : ""}
            </Link>
        </li>
    );
}
